import { ShipmentParcel, ShipmentParcelPreview } from 'api/types'
import { InboundConfig } from 'shared/RemoteConfig'
import {
  CustomInboundAsnConfirmModal,
  CustomInboundParcelConfirmModal,
} from 'pages/CustomInbound/CustomInboundConfirmModal'

export async function inboundParcelBeforeConfirm(parcel: ShipmentParcel[], operation: InboundConfig) {
  return new Promise((resolve, reject) => {
    if (operation.code !== 'inbound') resolve(true)
    CustomInboundParcelConfirmModal(parcel[0].header, resolve, reject)
  })
}

export async function inboundParcelAfterConfirm(parcel: ShipmentParcel, operation: InboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function inboundShipmentBeforeConfirm(
  shipment: ShipmentParcel,
  confirmPayload: any,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    CustomInboundAsnConfirmModal(confirmPayload, resolve, reject)
  })
}

export async function inboundShipmentAfterConfirm(
  parcel: ShipmentParcel,
  confirmResponse: ShipmentParcelPreview,
  operation: InboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}
