import React from 'react'
import { Product } from 'api/types'
import styled from '@emotion/styled'
import Box from './Box'

export default function ProductRow({ product, selected }: { product: Product; selected?: boolean }) {
  const itemUpc = product.code ?? '--'
  const style = product.style?.value
  const variant = product.variant?.value
  const originalDrawing = (product.description ?? '') + (product.originalDrawing ?? '')
  const description = product.variant?.valueDescription
  const sku = product.sku
  const size = product.size?.value
  const fit = product.fit?.value

  return (
    <KeyValueRowContainer flex hcenter row selected={selected}>
      <Box flex>
        {itemUpc}
        <Box style={{ flex: 1 }}>
          <TextAttributes row>{`${style} ${variant} ${originalDrawing}`}</TextAttributes>
          <TextAttributes row>{description}</TextAttributes>
          <TextAttributes row>{`${sku} ${size} ${fit}`}</TextAttributes>
        </Box>
      </Box>
      {product.encoded !== undefined && product.quantity !== undefined && (
        <Box>{`${product.encoded} / ${product.quantity}`}</Box>
      )}
    </KeyValueRowContainer>
  )
}

const KeyValueRowContainer = styled(Box)<{ selected?: boolean }>`
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  min-height: 74px;
  background-color: #f5f5f5;
  ${({ selected }) => (selected ? 'border: 3px solid #222222' : 'border: 3px solid transparent')};
  font-weight: 500;
  font-size: 22px;
`

const TextAttributes = styled(Box)`
  font-size: 16px;
`
