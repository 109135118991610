/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-const */
import { create } from 'apisauce'
import AppStore from 'AppStore'
import React, { Component } from 'react'
import RfidReader from 'shared/RfidReader'
import { sleep } from 'shared/utils'

export default class Test extends Component {
  connect = async (type: string) => {
    const antennaNFC = AppStore.defaultWorkstation?.antennas?.find((a) => a.rfidReader.supportedTagType === type)
    const rfidReaderNFC = antennaNFC!.rfidReader
    const response = await RfidReader.deviceManagerApi.post(
      `/rfidAntennas/${antennaNFC!.id}/start?sessionId=test&scanningMode=STREAM`,
      {
        type: `${rfidReaderNFC.type}`,
        id: rfidReaderNFC.code,
        ipAddress: rfidReaderNFC.ipAddress,
        rfidAntennas: [antennaNFC],
        maxConnectionTimeout: rfidReaderNFC.maxConnectionTimeout,
        port: rfidReaderNFC.port,
      }
    )
    if (!response.ok) {
      console.log(`fail start ${type}`)
    }
    // const wsConfigurations = `${RfidReader.workstation?.deviceManagerWebsocketPath}/rfidAntennas/scannings?antennaId=${
    //   antennaNFC!.id
    // }`
    await sleep(2000)
    const responseStop = await RfidReader.deviceManagerApi.post(
      `/rfidAntennas/${antennaNFC!.id}/stop?sessionId=test&scanningMode=STREAM`,
      {
        type: `${rfidReaderNFC.type}`,
        id: rfidReaderNFC.code,
        ipAddress: rfidReaderNFC.ipAddress,
        rfidAntennas: [antennaNFC],
        maxConnectionTimeout: rfidReaderNFC.maxConnectionTimeout,
        port: rfidReaderNFC.port,
      }
    )

    if (!responseStop.ok) {
      console.log('fail stop nfc')
    }
  }

  start = async () => {
    await AppStore.defaultWorkstation?.antennas.forEach(async (antenna) => {
      const rfidReaderNFC = antenna!.rfidReader
      const response = await RfidReader.deviceManagerApi.post(
        `/rfidAntennas/${antenna!.id}/start?sessionId=test&scanningMode=STREAM`,
        {
          type: `${rfidReaderNFC.type}`,
          id: rfidReaderNFC.code,
          ipAddress: rfidReaderNFC.ipAddress,
          rfidAntennas: [antenna],
          maxConnectionTimeout: rfidReaderNFC.maxConnectionTimeout,
          port: rfidReaderNFC.port,
        }
      )
      if (!response.ok) {
        console.log(`fail start antennas`)
      }
    })
  }

  stop = async () => {
    await AppStore.defaultWorkstation?.antennas.forEach(async (antenna) => {
      const rfidReaderNFC = antenna!.rfidReader
      const response = await RfidReader.deviceManagerApi.post(
        `/rfidAntennas/${antenna!.id}/stop?sessionId=test&scanningMode=STREAM`,
        {
          type: `${rfidReaderNFC.type}`,
          id: rfidReaderNFC.code,
          ipAddress: rfidReaderNFC.ipAddress,
          rfidAntennas: [antenna],
          maxConnectionTimeout: rfidReaderNFC.maxConnectionTimeout,
          port: rfidReaderNFC.port,
        }
      )
      if (!response.ok) {
        console.log(`fail stop antennas`)
      }
    })
  }

  async test() {
    try {
      await RfidReader.initialize()
      RfidReader.deviceManagerApi = create({
        baseURL: `${RfidReader.workstation!.deviceManagerBasePath}`,
        timeout: 3000,
      })
      for (let index = 0; index < 50; index++) {
        await this.start()
        await sleep(300)
        await this.stop()
        await sleep(300)
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    return <div onClick={() => this.test()}>testiamo</div>
  }
}
