import React from 'react'
import Select from 'react-select'
import { getField } from 'shared/utils'

type OptionConfig = { label: string; value: string; secondaryLabel?: string }

interface InputProps {
  placeholder?: string
  defaultValue?: any
  containerStyle?: React.CSSProperties
  controlStyle?: React.CSSProperties
  disabled?: boolean
  options: any[]
  config: OptionConfig
  borderColor?: string
  onSelect: (item?: any) => void
  transparent?: boolean
  isClearable?: boolean
  multiLabel?: boolean
}

export default function SelectComponent({
  onSelect,
  config,
  placeholder,
  defaultValue,
  containerStyle,
  controlStyle,
  options,
  borderColor = '#D2D2D2',
  disabled,
  transparent,
  isClearable,
  multiLabel,
}: InputProps) {
  const customStyles = {
    container: (base, { isFocused }) => ({
      ...base,
      height: 64,
      minHeight: 64,
      boxShadow: isFocused && '0px 0px 0px 3px rgba(47, 128, 237, 0.5)',
      outline: isFocused && 'transparent auto 0px!important',
      // eslint-disable-next-line no-nested-ternary
      border: isFocused ? '2px solid #2f80ed' : `2px solid ${borderColor}`,
      borderRadius: 10,
      ...containerStyle,
    }),
    control: (base) => ({
      ...base,
      height: 60,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 22,
      paddingLeft: 10,
      fontFamily: 'Roboto',
      WebkitFontSmoothing: 'antialiased',
      MozFontSmoothing: 'grayscale',
      borderRadius: 10,
      border: '2px solid transparent !important',
      backgroundColor: transparent ? 'transparent' : 'hsl(0,0%,100%)',
      '&:hover': {
        boxShadow: '0px 0px 0px 0px transparent !important',
        outline: 'transparent auto 0px !important',
        border: '2px solid transparent !important',
      },
      ...controlStyle,
    }),
    input: () => ({
      color: 'black',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#666666',
      fontFamily: 'Roboto',
    }),
    menu: (base) => ({
      ...base,
      fontFamily: 'Roboto',
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (base) => ({
      ...base,
      height: 50,
      paddingTop: 13,
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontFamily: 'Roboto',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontFamily: 'Roboto',
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (defaultStyles) => ({
      ...defaultStyles,
      paddingRight: 10,
      '& svg': { width: 26, height: 26 },
      '&::before': {
        border: '…',
        transform: 'rotate(…)',
      },
      '&::after': {
        border: '…',
        transform: 'rotate(…)',
      },
    }),
  }

  const onChangeItem = (item) => {
    if (!item) {
      onSelect()
      return
    }
    onSelect(item)
  }

  const getOptionLabel = (option: any) => {
    const label = getField(option, config.label)
    const secondaryLabel = config.secondaryLabel ? getField(option, config.secondaryLabel) : ''
    return multiLabel ? label + ' ' + secondaryLabel : label || secondaryLabel
  }

  return (
    <Select
      className="select"
      isClearable={isClearable}
      isSearchable
      styles={customStyles}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => getField(option, config.value)}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChangeItem}
      disabled={disabled}
      onClear
      options={options}
    />
  )
}
