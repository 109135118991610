import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Icons, KeyValueRow, SettingsModal } from 'components'
import api from 'api/api'
import config from 'config/config'
import FullScreen from 'screenfull'
import AppStore from 'AppStore'
import { askUserConfirmation, openModal } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import { LeftContainer, Spacer } from './Various'

export default function LeftHeader({
  header = true,
  style,
  children,
  mv = 50,
}: React.PropsWithChildren<{ header?: boolean; style?: any; mv?: number }>) {
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)

  const removeCustomEndpoint = async () => {
    const ok = await askUserConfirmation(
      'Rimuovi Endpoint Custom',
      `Vuoi tornare all'endpoint utilizzato di default nella station?`
    )

    if (ok) AppStore.setEndpoint(undefined)
  }

  const showSupportInfoModal = () => {
    openModal({
      title: __(T.titles.support),
      body: (
        <Box>
          <KeyValueRow label="Mail" variant="grey" value="support@temera.it" />
          <Spacer />
          <KeyValueRow label={__(T.misc.phone)} variant="grey" value="+39 0550981917" />
        </Box>
      ),
    })
  }

  return (
    <Container style={style}>
      {header && (
        <Box center mv={mv}>
          <Icons.RfidStation />
        </Box>
      )}
      <LeftContainer style={{ flex: 5 }}>
        {children}
        <Spacer />
        {api.getBaseURL() !== config.endpoint && (
          <KeyValueRow
            variant="inset"
            label="Custom backend"
            valueStyle={{ fontSize: 18 }}
            value={api.getBaseURL()}
            onClick={removeCustomEndpoint}
          />
        )}
      </LeftContainer>
      <BottomButtons row>
        <BottomButton onClick={() => setsettingsModalVisible(true)}>
          <Icons.Settings style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
        <Spacer />
        <BottomButton
          onClick={() => {
            // @ts-ignore
            FullScreen.toggle()
          }}
        >
          <Icons.Fullscreen style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
        <Spacer />
        <BottomButton onClick={showSupportInfoModal}>
          <Icons.Help style={{ width: 36, margin: 17, opacity: 0.8 }} />
        </BottomButton>
      </BottomButtons>
      <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.background1};
  overflow-y: hidden;
  width: 550px;
  max-width: 550px;
  min-width: 550px;
`

const BottomButtons = styled(Box)`
  position: absolute;
  bottom: 50px;
  left: 50px;
`

const BottomButton = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`
