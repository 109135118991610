import React from 'react'
import { Box, Spacer } from 'components'
import { FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react'

export function Switch({
  name,
  label,
  check,
  onChange,
}: {
  name: string
  label: string
  check?: boolean
  onChange: (checked: boolean) => void
}) {
  return (
    <Box row vcenter>
      <ChakraSwitch onChange={(e) => onChange(e.target.checked)} isChecked={check} check id={name} />
      <Spacer />

      <FormLabel htmlFor={name} mb="0">
        {label}
      </FormLabel>
    </Box>
  )
}
