import React, { useState } from 'react'
import { Box, Button, Form, Image, LeftHeader, Page } from 'components'
import Auth from 'api/Auth'
import { Container, RightContainer } from 'components/Various'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import AppStore from 'AppStore'
import styled from '@emotion/styled'
import config from 'config/config'

export default function Login() {
  const [showLogin, toggleLogin] = useState(false)
  const onLogin = async (data: any) => {
    try {
      await Auth.login(data.username, data.password)
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.authentication_failed),
        status: 'error',
      })
      return
    }
    try {
      await AppStore.loadInitalData()
      AppStore.reloadRouting()
    } catch (error) {
      ///ignore
    }
  }

  const openSSOurl = () => {
    window.location.assign(
      `${config.endpoint}${config.endpoint.endsWith('/') ? '' : '/'}azure/sso?state=${window.location.origin}`
    )
  }

  return (
    <Page title="Login" header={null}>
      <Container>
        <LeftHeader />
        <RightContainer>
          <Image alt="" src="assets/img/logo.png" style={{ marginBottom: 30, width: 200 }} />
          <FormContainer hcenter>
            <Button onClick={openSSOurl} variant="secondary" style={{ width: '100%' }} title={__(T.misc.login)} />
            <Spacer />
            {!showLogin && (
              <Button
                onClick={() => {
                  toggleLogin(!showLogin)
                }}
                variant="primary"
                style={{
                  background: 'transparent',
                  boxShadow: 'none',
                  textDecoration: 'underline',
                  maxWidth: 100,
                  height: 40,
                  fontSize: 15,
                  padding: 0,
                }}
                title={__(T.misc.admin)}
              />
            )}

            {showLogin && (
              <Form
                schema={[
                  { placeholder: __(T.misc.username), name: 'username', required: true, focus: true },
                  { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
                ]}
                onSubmit={onLogin}
                submitText={__(T.misc.login_tmr)}
              />
            )}
          </FormContainer>
        </RightContainer>
      </Container>
    </Page>
  )
}

const Spacer = styled.div`
  height: 1px;
  width: 80%;
  background-color: #454545;
  opacity: 0.1;
  margin-top: 10px;
  margin-bottom: 10px;
`

const FormContainer = styled(Box)`
  width: 350px;
`
