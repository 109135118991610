import React from 'react'
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { T, __ } from 'translations/i18n'

const PlaceholderText = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 27px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: default;
`

export default function AntennaPlaceholder({ placeholder }: { placeholder?: string }) {
  return (
    <Box center style={{ position: 'relative' }}>
      <Icons.Antenna />
      <PlaceholderText>{placeholder ?? __(T.messages.bring_an_item_to_antenna)}</PlaceholderText>
    </Box>
  )
}
