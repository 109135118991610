import React from 'react'
import { Box, Image, Table } from 'components'
import { T, __ } from 'translations/i18n'
import { GroupedShipmentProduct } from 'api/types'
import Products from 'api/Products'

interface Props {
  products: GroupedShipmentProduct[]
  loading: boolean
}

export default function GroupedProductsTable({ products, loading }: Props) {
  const totalQuantity = products.map((prod) => prod.expected + prod.unexpected).reduce((acc, curr) => acc + curr, 0)
  const structure = [
    {
      label: '',
      customRender: (product: GroupedShipmentProduct) => (
        <Box center p={10}>
          <Image
            width={100}
            height={100}
            style={{ objectFit: 'contain' }}
            src={Products.getImageUrl(product.product.code)}
            alt="item"
          />
        </Box>
      ),
    },
    {
      label: 'UPC',
      customRender: (product: GroupedShipmentProduct) => <>{product.product.code || '---'}</>,
    },
    {
      label: `${__(T.misc.style)} / ${__(T.misc.material)} / ${__(T.misc.color)} / ${__(T.misc.size)}`,
      customRender: (product: GroupedShipmentProduct) => (
        <>
          {`${product.product.style?.value || '---'} / ${product.product.material?.value || '---'} / ${
            product.product.color?.value || '---'
          } / ${product.product.size?.value || '---'}`}
        </>
      ),
      flex: 2,
    },
    {
      label: __(T.misc.quantity, { quantity: totalQuantity }),
      customRender: (product: GroupedShipmentProduct) => <>{product.expected + product.unexpected}</>,
    },
  ]

  return (
    <>
      <Table loading={loading} data={products} structure={structure} />
    </>
  )
}
