import UIfx from 'uifx'

const play = (file: string) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    const sound = new UIfx(file, {
      volume: 1,
    })
    sound.play()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export default {
  confirmation: () => play(require('../assets/sounds/confirmation.m4a')),
  error: () => play(require('../assets/sounds/error.m4a')),
  fail: () => play(require('../assets/sounds/fail.m4a')),
  next: () => play(require('../assets/sounds/next.m4a')),
  scan: () => play(require('../assets/sounds/scan.m4a')),
  success: () => play(require('../assets/sounds/success.m4a')),
  tap: () => play(require('../assets/sounds/tap.m4a')),
}
