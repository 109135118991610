import { Icons, Image } from 'components'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const errorColor = '#dd4949'

interface InputProps extends Partial<HTMLTextAreaElement> {
  onEnter?: (value: string) => void
  inputRef?: React.RefObject<HTMLTextAreaElement>
  error?: string
  barcode?: boolean
  autoFocus?: boolean
  style?: React.CSSProperties | any
  image?: string
  onChange?: (value: string) => void
  startFocus?: boolean
  label?: string
  cols?: number
  rows?: number
  maxLength?: number
  textAreaStyle?: React.CSSProperties
}

export default function TextAreaComponent({
  onEnter,
  inputRef = React.createRef<HTMLTextAreaElement>(),
  error,
  barcode,
  autoFocus,
  style,
  image,
  onChange,
  placeholder,
  defaultValue,
  required,
  startFocus,
  label,
  cols,
  rows,
  textAreaStyle,
  maxLength,
}: InputProps) {
  const [value, setvalue] = useState('')
  const [loading] = useState(false)
  const [cleared, setcleared] = useState(false)

  // TODO: handle onBarcodeScan with a debounce

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clear = () => {
    inputRef!.current!.value = '' as any
    setvalue('')
    if (!barcode && onEnter) {
      onEnter('')
    }
    onChange && onChange('')
    inputRef!.current!.focus()
  }

  useEffect(() => {
    if (startFocus) {
      inputRef?.current?.focus()
    }
    if (autoFocus) {
      inputRef?.current?.focus()
      inputRef?.current?.addEventListener('blur', () => {
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 300)
      })
    }
    if (!barcode) {
      return
    }
    if (error && !cleared) {
      clear()
      setcleared(true)
    }
    if (!error) {
      setcleared(false)
    }
  }, [error, clear, cleared, setcleared, barcode, inputRef, autoFocus, startFocus])

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value)
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && onEnter) onEnter(value)
  }

  return (
    <div style={style}>
      <Field className="float-label">
        <Label>
          {label}
          <TextAreaStyle
            error={error}
            autoFocus={autoFocus}
            placeholder={placeholder}
            cols={cols}
            rows={rows}
            maxLength={maxLength}
            defaultValue={defaultValue}
            required={required}
            ref={inputRef}
            style={textAreaStyle}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
          />
        </Label>
        <ImageWrap>
          {image && typeof image !== 'string' && value.length === 0 && (
            <image style={{ padding: 10, cursor: 'pointer', width: 24 }} />
          )}
          {image && typeof image === 'string' && value.length === 0 && <RightImage src={image} alt="" />}
          {loading && <LoaderImage />}
          {/* } */}
        </ImageWrap>
      </Field>
      {error && <ErrorStyle>* {error}</ErrorStyle>}
    </div>
  )
}

const TextAreaStyle = styled.textarea<{ error: any }>`
  flex: 1;
  height: 65px;
  background: #ffffff;

  // box-shadow: 0px 1px 4px ${({ error }) => (error ? 'rgba(221, 73, 73, 0.15)' : 'rgba(0, 0, 0, 0.15)')};
  border: ${({ error }) => (error ? `2px solid ${errorColor}` : '2px solid #D2D2D2')};
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  color: #333333;
  font-size: 22px;
  line-height: 26px;
  padding: 15px 15px;
  width: 100%;
  :focus {
    box-shadow: 0px 0px 0px 3px rgba(47, 128, 237, 0.5);
    outline: transparent auto 0px;
    border-radius: 10px;
    border: 2px solid #2f80ed;
  }
  :disabled {
    border: 2px solid transparent;
    color: #000000;
  }
`

const ImageWrap = styled.div`
  justify-content: center;
  position: absolute;
  margin: auto;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 10px;
`

const RightImage = styled(Image)`
  padding: 10px;
  cursor: pointer;
  width: 24px;
`

const LoaderImage = styled((props) => <Icons.Loader {...props} />)`
  width: 40px;
`

const ErrorStyle = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: ${errorColor};
`

const Field = styled.div`
  position: relative;
  margin: auto;
  flex: 1;
  display: flex;
`

const Label = styled.label`
  flex: 1;
`
