import React from 'react'
import { Skeleton } from '@chakra-ui/react'
import Box, { BoxProps } from './Box'

export default function KeyValueRowSkeleton(props: BoxProps) {
  return (
    <Box {...props}>
      <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={91} mb="10px" />
      <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={150} />
    </Box>
  )
}
