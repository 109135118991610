import React from 'react'
import Products from 'api/Products'
import { GroupedShipmentProduct } from 'api/types'
import { Image, SmallTagCounter, Box } from 'components'
import { T, __ } from 'translations/i18n'
import styled from '@emotion/styled'

function ShipmentProductStructure(totalProductsQuantity: number) {
  const ProductImage = styled(Image)`
    object-fit: contain;
    width: 125px;
    height: 100px;
    padding: 10px;
  `

  const _ = (value?: string) => {
    return value ?? '--'
  }

  return [
    {
      label: '',
      width: 140,
      customRender: ({ product }: GroupedShipmentProduct) => (
        <ProductImage style={{ marginTop: 10, marginBottom: 10 }} src={Products.getImageUrl(product.code)} />
      ),
    },
    {
      width: 240,
      label: __(T.misc.upc),
      customValue: ({ product }: GroupedShipmentProduct) => `${product.code}`,
    },
    {
      flex: 2,
      label: __(T.misc.product_header),
      customRender: ({ product }: GroupedShipmentProduct) => (
        //'Model / Aeshetic Variant / Descr. Model / Description A.V. / Sku / Size / Fit',
        <Box>
          {`${_(product.style?.value)} / ${_(product.variant?.value)} / 
          ${(product.description ?? '') + (product.originalDrawing ?? '')} / ${_(product.variant?.valueDescription)} / 
          ${_(product.sku)} / ${_(product.size?.value)} / ${_(product.fit?.value)}`}
        </Box>
      ),
    },
    {
      label: __(T.misc.quantity, { quantity: totalProductsQuantity }),
      width: 180,
      customRender: (product: GroupedShipmentProduct) => (
        <SmallTagCounter
          detected={product.detected + product.unexpected}
          unexpected={product.unexpected}
          expected={product.expected}
        />
      ),
    },
  ]
}

export default {
  ShipmentProductStructure,
}
