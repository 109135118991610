import React, { Component } from 'react'
import { Box, Image, Spacer } from 'components'
import styled from '@emotion/styled'
import { TmrProduct } from 'api/types'
import { T, __ } from 'translations/i18n'
import Products from 'api/Products'
import { getField } from 'shared/utils'

interface Props {
  product?: TmrProduct
  fields?: { path: string; label: string }[]
}

export default class ProductInfoBox extends Component<Props> {
  render() {
    const { product, fields = [] } = this.props
    return (
      <CardContainer>
        <ProductBox flex>
          <Title>{__(T.misc.product)}</Title>
          <Box row>
            <Image transparent width={120} variant="boxed" src={Products.getImageUrl(product?.code)} />
            <Spacer />
            <Box flex>
              <CodeLabel>{product?.code ?? '---'}</CodeLabel>
              <DescriptionLabel>{product?.description ?? '---'}</DescriptionLabel>
              <Spacer />
              {fields.map((field) => (
                <ContainerValue row>
                  <LabelText>{field.label}</LabelText>
                  <ValueText>{getField(product, field.path)}</ValueText>
                </ContainerValue>
              ))}
            </Box>
          </Box>
        </ProductBox>
      </CardContainer>
    )
  }
}

const ContainerValue = styled(Box)`
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 0px solid #e0e0e0;
  }
`

const CardContainer = styled(Box)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
`

const ProductBox = styled(Box)`
  padding: 20px 20px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;

  opacity: 0.4;
  margin-bottom: 20px;
`

const LabelText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
`
const ValueText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
`

const CodeLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
`

const DescriptionLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`
