import React, { Component } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Spacer } from 'components/Various'

export interface ModalProps extends Partial<ChakraModalProps> {
  title?: string
  visible?: boolean
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  containerStyle?: React.CSSProperties
  fullContent?: boolean
}

interface State {
  visible: boolean
}

class Modal extends Component<ModalProps, State> {
  static Title: Function

  state: State = {
    visible: this.props.visible ?? false,
  }

  show = () => {
    this.setState({ visible: true })
  }

  hide = () => {
    this.setState({ visible: false })
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      visible: nextProps.visible,
    }
  }

  render() {
    const { onClose, fullContent, title, children, size, ...rest } = this.props
    const { visible } = this.state
    return (
      <ChakraModal {...rest} size={size} isOpen={visible} onClose={onClose} isCentered>
        <ModalOverlay />
        <Content fullContent={fullContent}>
          {title && (
            <>
              <Modal.Title>{title}</Modal.Title>
              <Spacer />
            </>
          )}
          {!fullContent && (
            <Body>
              <div style={{ padding: 3 }}>{children}</div>
            </Body>
          )}
          {fullContent && children}
        </Content>
      </ChakraModal>
    )
  }
}

Modal.Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 3px;
  flex: 1;
`
const Body = styled(ModalBody)`
  overflow: auto;
`
const Content = styled(ModalContent)<{ fullContent?: boolean }>`
  ${({ fullContent }) =>
    !fullContent &&
    `
    padding: 25px 32px;
  `}
  overflow: hidden;
`

export default Modal
