import React, { Component } from 'react'
import { InfoCard, Input } from 'components'
import { Spacer } from 'components/Various'
import { T, __ } from 'translations/i18n'
import { __isDev } from 'shared/utils'

interface Props {
  productionOrder?: any
  productionOrderValue?: string
  errorProductionOrder?: string
  inputProductionOrder?: React.RefObject<HTMLInputElement>
  clearProductionOrder: (e: any) => void
  searchProductionOrder: (value: string) => void
}

export default class ProductionOrderInputBox extends Component<Props> {
  render() {
    const {
      productionOrder,
      productionOrderValue,
      errorProductionOrder,
      inputProductionOrder,
      clearProductionOrder,
      searchProductionOrder,
    } = this.props
    if (!productionOrder) {
      return (
        <Input
          autoFocus
          error={errorProductionOrder}
          inputRef={inputProductionOrder}
          defaultValue={__isDev ? 'ORDER3' : undefined}
          placeholder={__(T.misc.production_order)}
          onEnter={searchProductionOrder}
          barcode
        />
      )
    }

    const itemSummary = {} as any
    if (productionOrder?.description) {
      itemSummary[__(T.misc.description)] = productionOrder?.description
    }

    return (
      <>
        <InfoCard
          onClose={clearProductionOrder}
          title={__(T.misc.production_order)}
          subtitle={productionOrder?.code ?? productionOrderValue}
          item={itemSummary}
        />
        <Spacer />
      </>
    )
  }
}
