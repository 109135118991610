import QualityControl from 'api/QualityControl'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export default class QualityControlAgent {
  static async initializeAgent() {
    try {
      const res = await QualityControl.testConnection()
      if (res.status === 200) {
        const tagProperties = res.data.replaceAll('"', '').split(';')
        if (tagProperties[1] !== '0') throw Error(tagProperties[2])
        return
      }
      throw Error('Impossibile connettersi al lettore')
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  static async readTag(operatoreCQ: string, idPostazione: string) {
    try {
      const res = await QualityControl.readTag({ operatoreCQ, idPostazione })
      if (res.status === 200) {
        const tagProperties = res.data.replaceAll('"', '').split(';')
        return { uid: tagProperties[0], status: tagProperties[1], errorDesc: tagProperties[2], uuid: tagProperties[3] }
      }
      throw Error('Impossibile leggere il tag')
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      return Promise.reject(error.message)
    }
  }

  static async stopReader() {
    const res = await QualityControl.stopReader()
    return res.data ?? false
  }

  static async writeTagCQ(
    operatoreCQ: string,
    idPostazione: string,
    uId: string,
    lavorante: string,
    serieProduttiva: string,
    collezione: string,
    sku: string,
    taglia: string,
    //calzata: string,
    cliente: string,
    zona: string
    //idcau: string
  ) {
    try {
      const res = await QualityControl.writeTagCQ({
        operatoreCQ,
        idPostazione,
        uId,
        lavorante,
        serieProduttiva,
        collezione,
        sku,
        taglia,
        //calzata,
        cliente,
        zona,
        //idcau,
      })
      if (res.status === 200) {
        const tagProperties = res.data.replaceAll('"', '').split(';')
        return { uid: tagProperties[0], status: tagProperties[1], errorDesc: tagProperties[2], uuid: tagProperties[3] }
      }
      throw new Error('Impossibile battezzare il tag')
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  static async writeTagSC(operatoreCQ: string, uId: string, idPostazione: string, idcau: string) {
    try {
      const res = await QualityControl.writeTagSC({
        operatoreCQ,
        uId,
        idPostazione,
        idcau,
      })
      if (res.status === 200) {
        const tagProperties = res.data.replaceAll('"', '').split(';')
        return { uid: tagProperties[0], status: tagProperties[1], errorDesc: tagProperties[2], uuid: tagProperties[3] }
      }
      throw new Error('Impossibile scartare il tag')
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  static async cancelTag(operatoreCQ: string, uId: string, idPostazione: string) {
    try {
      const res = await QualityControl.cancelTag({
        operatoreCQ,
        uId,
        idPostazione,
      })
      if (res.status === 200) {
        const tagProperties = res.data.replaceAll('"', '').split(';')
        return { uid: tagProperties[0], status: tagProperties[1], errorDesc: tagProperties[2], uuid: tagProperties[3] }
      }
      throw new Error('Impossibile eliminare associazione')
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }
}
