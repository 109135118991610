import EncodingsQC from 'api/EncodingsQC'
import { CQTag, TestingSheet } from 'api/types'
import AppStore from 'AppStore'
import Sounds from 'shared/Sounds'
import { askUserConfirmation } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import QualityControlAgent from './QualityControlAgent'

export default class QualityProvider {
  static async searchSerie(
    isPelletteria: boolean,
    code: string,
    serie: any,
    operation: any,
    closeSerie: any,
    searchSerie: any,
    showSelectProductModal: any,
    changeStatusSerie: any
  ) {
    if (isPelletteria && code.length !== 10) throw new Error(__(T.error.serie_code_10_characters))
    if (!isPelletteria && code.length !== 14) throw new Error(__(T.error.serie_code_14_characters))
    const data = await EncodingsQC.getCustomProductionOrder(code, operation)

    if (!data) throw new Error(__(T.error.serie_not_found))

    //controllo se la serie appartiene al place dell'utente
    if (data.worker.code !== AppStore.defaultPlace?.code) throw new Error(__(T.error.serie_wrong_place))

    if (!AppStore.isInspector && data.status === 'CLOSED') throw new Error(__(T.error.serie_inspector_check))

    //se la nuova serie (10 caratteri non corrisponde a quella corrente allora va chiusa quella corrente e aperta quella nuova)
    if (serie && data.code !== serie?.code) {
      if (
        await askUserConfirmation(
          __(T.messages.serie_change_confirm),
          __(T.messages.serie_close_product_not_associated)
        )
      ) {
        if (!(await closeSerie())) return
        searchSerie(code)
        return
      }
      return
    }

    let row

    if (code.length === 10 && data.rows.length > 1) row = await showSelectProductModal(data.rows)

    if (!row) {
      if (isPelletteria) row = data.rows[0]
      else row = data.rows.find((r) => r.rowCode === code) as any
    }

    if (!row.product) throw Error(__(T.error.serie_not_found))

    //quando l'ispettore apre la serie allora la metto in stato inspected_2
    if (AppStore.isInspector) await changeStatusSerie('INSPECTED_2', 'ispezione', data.id)

    const newSerie = {
      id: data.id,
      code: data.code,
      row: row,
      product: row.product,
      worker: data.worker,
      customerOrder: data.customerOrder,
      productId: row.product.id,
      nationCode: data.nationCode,
      destination: data.destination,
      qualityCode: data.qualityCode,
      data: {
        values: {
          'Tag/Cal': `${row.product.size.value}/${row.product.fit.value}`,
          Modello: row.product.style.value,
          Variante: row.product.variant.value,
          Stagione: row.product.season.slice(0, 2) + row.product.season.slice(4),
          Lavorante: data.worker.description ?? 'aa',
        },
        descriptions: {
          Taglia: row.product.size.valueDescription,
          Calzata: row.product.fit.valueDescription,
          Modello: row.product.style.valueDescription,
          Variante: row.product.variant.valueDescription,
        },
      },
      counters: {
        totalPieces: data.availableQuantity,
        tested: data.tested,
        discardedPieces: data.discarded,
        inspectorTested: data.inspectorTested,
        toBeTested: data.toBeTested > data.tested ? data.tested : data.toBeTested,
        availableQuantity: data.availableQuantity,
      },
    }
    Sounds.tap()
    return newSerie
  }

  static async verify(
    tag: CQTag,
    options: any,
    serie: any,
    operation: any,
    setError: any,
    setErrorForceCollaudo: any,
    Associate: any,
    Drop: any,
    Delete: any,
    setItem: any,
    forceCollaudo: any,
    forceDrop: any,
    stopReader: any
  ) {
    const mode = options.find((opt) => opt.active === true).value

    const res = await EncodingsQC.verify({
      configurationId: operation.id,
      identifiers: [{ code: tag.uid, type: 'NFCTag' }] as any,
      productionOrderId: serie.id,
      productId: serie.productId,
    })

    if (res.errors.find((error) => error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR')) {
      setError(__(T.error.status_not_admitted))
      return
    }

    if (!res.item) {
      if (AppStore.isInspector) {
        setError(__(T.error.not_collaudated_product))
        return
      }
      if (mode === 'collaudo') {
        await stopReader(false, true)
        await Associate()
        return
      }
      if (mode === 'scarto') {
        await stopReader()
        await Drop()
        return
      }
      if (mode === 'cancellazione') {
        setError(__(T.error.not_collaudated_product))
        return
      }
    }

    const item = await EncodingsQC.fetchItemById(res.item.id)

    if (!res.success && res.errors.find((error) => error.errorCode === 'FERRAGAMO.ERROR.SERIES_MISMATCH_ERROR')) {
      setError(__(T.error.not_associated_product))
      return
    }

    if (mode === 'collaudo') {
      if (AppStore.isInspector) {
        if (
          AppStore.isInspector &&
          !res.success &&
          res.errors.length === 1 &&
          res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ2'
        ) {
          setError(__(T.error.product_already_associated))
        } else if (
          (AppStore.isInspector &&
            !res.success &&
            res.errors.length === 1 &&
            res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SC') ||
          res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SCNR'
        ) {
          setError(__(T.error.product_already_dropped))
        } else if (
          res.success ||
          (AppStore.isInspector &&
            !res.success &&
            res.errors.length === 1 &&
            res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ')
        ) {
          await stopReader(true)
          setItem(item)
          await forceCollaudo('CQ2')
        }
      } else if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.length === 1 &&
        res.errors.find((err) => err.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SCNR')
      ) {
        setError(__(T.error.product_irreversible_dropped))
      } else if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.length === 1 &&
        res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ'
      ) {
        setError(__(T.error.product_already_associated))
      } else if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.length === 1 &&
        res.errors[0].errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ2'
      ) {
        setError(__(T.error.product_already_associated_by_inspector))
      } else if (res.success) {
        await stopReader()
        setItem(item)
        await Associate()
      } else {
        await stopReader(true)
        setErrorForceCollaudo(item)
      }
    } else if (mode === 'scarto') {
      if (AppStore.isInspector) {
        if (
          !res.success &&
          res.errors.find(
            (error) =>
              error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ' ||
              error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_CQ2'
          )
        ) {
          await stopReader()
          forceDrop(__(T.messages.drop_proceed))
          return
        }
        if (
          AppStore.isInspector &&
          !res.success &&
          res.errors.find(
            (error) =>
              error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SC' ||
              error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SCNR'
          )
        ) {
          await stopReader()
          forceDrop(__(T.messages.drop_proceed))
          return
        }
      } else if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.find((error) => error.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SC')
      ) {
        setError(__(T.error.product_already_dropped))
        return
      }
      if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.find((err) => err.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SCNR')
      ) {
        setError(__(T.error.product_irreversible_dropped))
      } else {
        await stopReader()
        await Drop()
      }
    } else if (mode === 'cancellazione') {
      if (
        !AppStore.isInspector &&
        !res.success &&
        res.errors.find((err) => err.errorCode === 'FERRAGAMO.ERROR.TAG_STATUS_ERROR_SCNR')
      ) {
        setError(__(T.error.product_irreversible_dropped))
      } else if (res.success && !res.item) {
        const res = await QualityControlAgent.cancelTag(
          AppStore.loggedUser?.username as string,
          tag.uid,
          AppStore.defaultPostazione ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.tag_reset_error))
        }
      } else {
        await stopReader()
        setItem(item)
        await Delete()
      }
    }
  }

  static async searchQualitySheet(qualityCode?: string, workerCode?: string, productStyle?: string) {
    if (!qualityCode || !workerCode || !productStyle) return undefined
    const data = await EncodingsQC.getQualitySheet(qualityCode, workerCode, productStyle)
    return data
  }

  static async searchTestingSheet(serieId: string) {
    if (!serieId) return undefined
    const data = await EncodingsQC.getTestingSheet(serieId)
    return data
  }

  static async createTestingSheet(testingSheet: Partial<TestingSheet>) {
    const data = await EncodingsQC.createTestingSheet(testingSheet)
    return data
  }

  static async updateTestingSheet(testingSheet: Partial<TestingSheet>) {
    const data = await EncodingsQC.updateTestingSheet(testingSheet)
    return data
  }

  static async addFilesQualitySheet(testingSheetCode: string, files: any) {
    const data = await EncodingsQC.addFilesQualitySheet(testingSheetCode, files)
    return data
  }
}
