import React, { Component } from 'react'
import { getMatchParams } from 'shared/router'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import OutboundByShipment from './OutboundByShipment'
import OutboundByParcel from './OutboundByParcel'

export default class Outbound extends Component {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  render() {
    if (this.operation.outboundMode === 'parcelByParcel') return <OutboundByParcel operation={this.operation} />
    if (this.operation.outboundMode === 'shipment') return <OutboundByShipment operation={this.operation} />

    throw new Error(`${this.operation.outboundMode} operation mode not defined`)
  }
}
