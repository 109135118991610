import React from 'react'
import styled from '@emotion/styled'
import { Box, Icons, KeyValueRow } from 'components'
import Button, { ButtonVariant } from './Button'

export type HeaderDetail = { label: string; value?: string; onPress?: () => void }
export type HeaderAction = { text: string; onPress: () => void; variant?: ButtonVariant }

type TempHeaderProps = {
  title: string
  details?: HeaderDetail[]
  actions?: HeaderAction[]
  children?: any
  onBackPress: () => void
  loading?: boolean
}

export default function Header({ title, details, actions, onBackPress, children, loading = false }: TempHeaderProps) {
  return (
    <div style={{ marginBottom: 90 }}>
      <Container>
        <Box flex row vcenter>
          <BackButtonContainer onClick={onBackPress}>
            <Icons.LeftArrow />
          </BackButtonContainer>
          <TitleStyle>{title}</TitleStyle>
        </Box>
        <RightSide>
          {details?.map(({ label, value, onPress }, index) => (
            <KeyValueRow
              key={index}
              variant="raw"
              onClick={onPress}
              loading={loading}
              label={label}
              value={value}
              style={{ marginLeft: 15, maxWidth: 300 }}
            />
          ))}

          {actions?.map((action) => (
            <Button key={action.text} onClick={action.onPress} style={{ marginLeft: 10 }}>
              {action.text}
            </Button>
          ))}

          {children}
        </RightSide>
      </Container>
    </div>
  )
}

const Container = styled.div`
  flex: 1;
  height: 90px;
  background-color: #eaeaea;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  overflow: hidden;
`

const BackButtonContainer = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  background-color: white;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`

const TitleStyle = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  padding-left: 20px;
`

const RightSide = styled.div`
  justify-content: right;
  align-items: center;
  margin-right: 14px;
  display: flex;
`
