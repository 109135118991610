import { Grid, GridItem } from '@chakra-ui/react'
import { Modal, Input, Button, Box, KeyValueRow, EmptyList, Icons } from 'components'
import { Spacer } from 'components/Various'
import React, { Component } from 'react'
import { getField } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export interface SelectItemModalProps {
  onClose?: () => void
  visible?: boolean
  options: any[]
  columns?: number
  onSelect: (item: any[]) => void
  title?: string
  selected?: any[]
  field: string
  label?: string
  multiple?: boolean
  searchable?: boolean
  customRowRender?: (opt: any, selected: boolean) => JSX.Element
  customFooter?: () => JSX.Element
  forceSelect?: boolean
  customBtnText?: string
}

interface State {
  filter: string
  selected: any[]
}

export default class SelectItemModal extends Component<SelectItemModalProps, State> {
  state: State = {
    filter: '',
    selected: this.props.selected ?? [],
  }

  onOptionSelect = (opt) => {
    const { multiple, field, onSelect, onClose } = this.props
    const { selected } = this.state
    if (!multiple) {
      onSelect([opt])
      onClose?.()
      return
    }
    const itemAlreadySelected = selected.findIndex((s) => getField(s, field) === getField(opt, field))
    if (itemAlreadySelected >= 0) selected.splice(itemAlreadySelected, 1)
    else selected.push(opt)
    this.setState({ selected })
  }

  onSave = () => {
    const { onSelect, onClose } = this.props

    onSelect(this.state.selected)
    onClose?.()
  }

  onSearchEnter = () => {
    const { options, field } = this.props
    const { filter } = this.state
    const filtered = options.filter((opt) => getField(opt, field).toLowerCase().includes(filter.toLowerCase()))
    if (filtered.length === 1) {
      this.onOptionSelect(filtered[0])
      this.setState({ filter: '' })
    }
  }

  render() {
    const {
      onClose,
      visible,
      searchable,
      options,
      columns = 1,
      title = __(T.titles.select_item),
      field,
      multiple,
      customRowRender,
      customFooter,
      forceSelect = false,
      label,
      customBtnText,
    } = this.props
    const { filter, selected } = this.state

    const filtered = options.filter((opt) => getField(opt, field).toLowerCase().includes(filter.toLowerCase()))
    return (
      <Modal
        visible={visible}
        size={`${columns > 1 ? columns : ''}xl` as any}
        onClose={() => onClose?.()}
        title={title}
      >
        {searchable && (
          <>
            <Input
              label={__(T.misc.filter)}
              onChange={(txt) => this.setState({ filter: txt })}
              onEnter={this.onSearchEnter}
            />
            <Spacer />
          </>
        )}
        <Box style={{ minHeight: '50vh', overflow: 'auto' }}>
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4}>
            {filtered.length > 0 &&
              filtered.map((opt, index) => {
                const checkSelected = selected.find((s) => getField(s, field) === getField(opt, field))
                return (
                  <>
                    <GridItem key={index} style={{ display: 'flex' }} onClick={() => this.onOptionSelect(opt)}>
                      {customRowRender && customRowRender(opt, !!checkSelected)}
                      {!customRowRender && (
                        <KeyValueRow
                          variant="grey"
                          style={{ flex: 1, minHeight: 70 }}
                          valueStyle={{ whiteSpace: 'unset' }}
                          label={label && getField(opt, label)}
                          value={getField(opt, field)}
                          borderColor={checkSelected ? '#222222' : undefined}
                          image={checkSelected ? <Icons.CheckFilled /> : undefined}
                        />
                      )}
                    </GridItem>
                  </>
                )
              })}
            {filtered.length === 0 && <EmptyList />}
          </Grid>
        </Box>
        {customFooter && customFooter()}
        {multiple && (
          <Box flex row mt={15}>
            {!forceSelect && (
              <>
                <Button variant="secondary" style={{ flex: 0 }} onClick={onClose}>
                  {customBtnText || __(T.misc.confirm)}
                </Button>
                <Spacer />
              </>
            )}
            <Button style={{ flex: 1 }} disabled={forceSelect && selected.length === 0} onClick={this.onSave}>
              {__(T.misc.confirm)}
            </Button>
          </Box>
        )}
      </Modal>
    )
  }
}
