import { TmrUser } from 'api/types'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
// import { showNotification } from 'helpers/Notifications'
import api, { responseErrorCheck } from './api'

export default class Auth {
  static async login(username: string, password: string): Promise<string | undefined> {
    const response = responseErrorCheck(await api.post<{ value: string }>(`auth/login`, { username, password }))
    if (response) {
      const { value } = response
      await AppStore.saveAuthToken(value)
      return value
    }
    return undefined
  }

  static async logout(): Promise<void> {
    await AppStore.logout()
  }

  static async loggedUser(): Promise<TmrUser | undefined> {
    try {
      const res = await api.get<TmrUser>(`auth/loggedUser`)
      return responseErrorCheck<TmrUser>(res)
    } catch (error) {
      throw Error(__(T.error.user_disabled))
    }
  }

  static setHeaderAccessToken(token: string) {
    return api.setHeaders({
      // set headers to 'api' directly otherwise wont work
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token || undefined}`,
      //'x-tmr-token': token,
    })
  }
}
