import React, { Component } from 'react'
import { Box, Button } from 'components'
import { MotionStyle } from 'framer-motion'
import { ButtonVariant } from './Button'
import { PlaceholderError } from './Various'

export interface Action {
  label: string
  callback: () => void
  buttonVariant?: ButtonVariant
}

interface Props {
  actions: Action[]
  containerStyle?: MotionStyle
  buttonsStyle?: React.CSSProperties
  errorMessage?: string
}

export default class ActionsBox extends Component<Props> {
  render() {
    const { actions, containerStyle, buttonsStyle, errorMessage } = this.props

    const boxStyle: MotionStyle = {
      position: 'relative',
      borderRadius: '10px',
      boxShadow: 'inset 0px 1px 4px rgba(0, 0, 0, 0.15)',
      backgroundColor: '#EDEDED',
      minWidth: 380,
      ...containerStyle,
    }
    const buttons: React.CSSProperties = { marginBottom: 20, ...buttonsStyle }
    return (
      <Box style={boxStyle} bgGrey p={30}>
        {actions.map((action) => (
          <Button title={action.label} onClick={action.callback} variant={action.buttonVariant} style={buttons} />
        ))}
        {!!errorMessage && <PlaceholderError>{errorMessage}</PlaceholderError>}
      </Box>
    )
  }
}
