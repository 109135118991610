import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'

interface Props {
  text?: string
  status?: string
  width?: number
}

export default function StatusLabel({ text, status, width }: Props) {
  if (!text && !status) return <></>

  return (
    <div style={{ display: 'flex' }}>
      <Container vcenter style={{ maxWidth: width }} text={status ?? text}>
        {text ?? status}
      </Container>
    </div>
  )
}

const Container = styled(Box)<{ text }>`
  background-color: ${({ text }) => {
    switch (text?.toLowerCase()) {
      // return '#AAD6FF'
      case 'open':
      case 'draft':
      case 'in_inbound':
        return '#FBBF66'
      case 'confirmed':
      case 'sent':
      case 'received':
      case 'ready_to_receive':
      case 'ready_to_send':
        return '#74F1A9'
      case 'in_transit':
      case 'receive_await':
      case 'send_await':
      case 'pending':
        return '#82c3ff'
      case 'error':
        return '#FD7575'
      default:
        return '#C4C4C4'
    }
  }};
  color: black;
  font-weight: bold;
  font-size: 16px;
  padding: 0px 12px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  text-overflow: ellipsis;
`
