import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { T, __ } from 'translations/i18n'

export default function EmptyList({
  placeholder = {
    title: __(T.messages.list_empty),
    subtitle: __(T.messages.looks_like_anything_in_list),
  },
  style,
}: {
  placeholder?: { title?: string; subtitle?: string }
  style?: React.CSSProperties
}) {
  return (
    <Container flex center height="100%" style={style}>
      <PlaceholderTitle>{placeholder.title}</PlaceholderTitle>
      <PlaceholderSubtitle>{placeholder.subtitle}</PlaceholderSubtitle>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: #f9f9f9;
  border-radius: 10px;
`

const PlaceholderTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`

const PlaceholderSubtitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`
