/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { Box, ProductInfoBox, Modal, Spacer, Card, IdentifierRow, Icons, RoundedLabel } from 'components'
import { CustomTmrItem, TmrTransfer } from 'api/types'
import { T, __ } from 'translations/i18n'
import Items from 'api/Items'
import { getDatetime, showToast } from 'shared/utils'
import styled from '@emotion/styled'
import { color, Skeleton, SkeletonCircle } from '@chakra-ui/react'

interface Props {
  onClose: () => void
  item: CustomTmrItem
  visible: boolean
}

interface State {
  transfers?: TmrTransfer[]
}

export default class CustomItemInfoModal extends Component<Props, State> {
  state: State = {
    transfers: undefined,
  }
  productFields: { path: string; label: string }[] = [
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
    { path: 'variant.value', label: __(T.misc.variant) },
    { path: 'variant.valueDescription', label: __(T.misc.description) },
    { path: 'sku', label: __(T.misc.sku) },
    { path: 'fit.value', label: __(T.misc.fit) },
  ]

  async componentDidMount() {
    const { item } = this.props
    try {
      this.setState({ transfers: await Items.getTransfers(item) })
    } catch (e) {
      showToast({
        title: __(T.error.error),
        description: e.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  render() {
    const { visible, onClose, item } = this.props
    const { transfers } = this.state
    return (
      <Modal size="5xl" onClose={onClose} visible={visible} fullContent>
        <Box flex row>
          <Box style={{ overflowY: 'scroll', maxHeight: '90vh' }} flex ph={35} pv={20}>
            <Modal.Title style={{ flex: 0 }}>Item Info</Modal.Title>
            <Spacer />
            <RoundedLabel maxWidth={230} label={'Serie'} value={item?.productionOrderRow?.order?.code} />
            <Spacer />
            <ProductInfoBox product={item.product} fields={this.productFields} />
            <Spacer />
            <Card title="Identifiers">
              {item.itemIdentifiers?.map((identifier, index) => (
                <IdentifierRow identifier={identifier} last={index === item.itemIdentifiers.length - 1} />
              ))}
            </Card>
          </Box>
          <Box
            pl={55}
            pr={30}
            pt={75}
            bgGrey
            width={420}
            style={{
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
              height: '90vh',
              maxHeight: '90vh',
              overflow: 'auto',
            }}
          >
            {transfers?.map((transfer, index) => (
              <TransferRow transfer={transfer} first={index === 0} last={index === transfers.length - 1} />
            ))}
            {!transfers && (
              <>
                <TransferRow />
                <TransferRow last />
              </>
            )}
          </Box>
        </Box>
      </Modal>
    )
  }
}

class TransferRow extends Component<{ transfer?: TmrTransfer; first?: boolean; last?: boolean }> {
  getType = () => {
    const { transfer } = this.props
    if (transfer?.movement.type.toLowerCase() === 'encodingdto') return 'Encoding'
    if (transfer?.movement.type.toLowerCase() === 'outboundshippingdto') return 'Outbound'
    if (transfer?.movement.type.toLowerCase() === 'inboundshippingdto') return 'Inbound'
    if (transfer?.movement.type.toLowerCase() === 'encodingdto') return 'Encoding'
    if (transfer?.movement.type.toLowerCase() === 'transfertozonedto') return 'Transfer To Zone'
    return ''
  }

  getIcon = () => {
    const type = this.getType()
    if (type === 'Encoding') return <Icons.Encoding />
    if (type === 'Inbound') return <Icons.Inbound />
    if (type === 'Outbound') return <Icons.Outbound />
    if (type === 'Transfer To Zone') return <Icons.TransferToZone />
    return <Icons.Encoding />
  }

  render() {
    const { transfer, first, last } = this.props
    const hasInboundParcelCode = this.getType() === 'Inbound' && !!transfer?.movement?.inboundParcel?.parcel?.code
    const hasOutboundParcelCode = this.getType() === 'Outbound' && !!transfer?.movement?.outboundParcel?.parcel?.code

    return (
      <>
        <Box row>
          <Box hcenter>
            {transfer && (
              <IconContainer p={6} first={first} center height={51} width={51}>
                {this.getIcon()}
              </IconContainer>
            )}
            {!transfer && <SkCircle startColor="#E6E6E6" endColor="#c8c8c8" size="50px" />}
            <VerticalSeparator last={last} />
          </Box>
          <Box flex ml={20}>
            {transfer && (
              <>
                <DescriptionLabel>
                  {`${transfer?.destination.place?.description ?? transfer?.destination.place?.code ?? '---'} - ${
                    transfer?.destination?.zoneType ?? '---'
                  }`}
                  <br />
                  {`${this.getType()}`}
                </DescriptionLabel>
                {hasInboundParcelCode && (
                  <ParcelLabel>{`${__(T.misc.parcel)}: ${
                    transfer?.movement?.inboundParcel?.parcel?.code
                  }`}</ParcelLabel>
                )}
                {hasOutboundParcelCode && (
                  <ParcelLabel>{`${__(T.misc.parcel)}: ${
                    transfer?.movement?.outboundParcel?.parcel?.code
                  }`}</ParcelLabel>
                )}
                <DateLabel>{getDatetime(transfer?.creationDate)}</DateLabel>
              </>
            )}
            {!transfer && (
              <>
                <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={91} mb="10px" />
                <Skeleton startColor="#E6E6E6" endColor="#c8c8c8" height={19} width={91} mb="10px" />
              </>
            )}
          </Box>
        </Box>
      </>
    )
  }
}

const IconContainer = styled((props) => <Box {...props} />)`
  border-radius: 51px;
  background-color: ${({ first }) => (first ? '#c6eaff' : 'white')};
`

const VerticalSeparator = styled((props) => <Box {...props} />)`
  margin: 6px 0px;
  height: 64px;
  width: 1px;
  ${({ last }) => !last && 'border-right: 1px dashed #898989;'};
`

const DescriptionLabel = styled((props) => <Box {...props} />)`
  font-weight: 900;
  font-size: 16px;
`

const DateLabel = styled((props) => <Box {...props} />)`
  font-weight: 400;
  font-size: 14px;
`

const ParcelLabel = styled((props) => <Box {...props} />)`
  font-weight: 400;
  font-size: 16px;
`

const SkCircle = styled(SkeletonCircle)`
  border-radius: 9999px !important;
`
