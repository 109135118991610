import React, { Component } from 'react'
import { Box, Icons } from 'components'
import styled from '@emotion/styled'
import Dropzone from 'react-dropzone'
import { CenteredContainer } from './modals/ImageDetailModal'

interface Props {
  getFiles: (acceptedFiles: any) => void
}

export default class AddImageComponent extends Component<Props> {
  toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64 = event?.target?.result as string
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          base64: base64.substring(base64.indexOf(',') + 1, base64.length) ?? '',
        })
        //console.log(`preso base64 di ${file.name}`)
        resolve(true)
      }
      //console.log(`inizio lettura di ${file.name}`)
      reader.readAsDataURL(file)
    })
  }

  render() {
    return (
      <Box style={{ maxHeight: '70vh' }}>
        <CenteredContainer>
          <Dropzone
            onDrop={async (acceptedFiles) => {
              //******* accepted files handlings ********/
              await Promise.all(
                acceptedFiles.map(async (file) => {
                  await this.toBase64(file)
                })
              )
              //console.log('settato lo states dei files')
              this.props.getFiles(acceptedFiles)
              //******* end accepted file handlings *********** */
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <AttachmentsBoxDashed {...getRootProps()}>
                <input {...getInputProps()} />
                <Icons.Placeholder />
                <Text>Aggiungi</Text>
              </AttachmentsBoxDashed>
            )}
          </Dropzone>
        </CenteredContainer>
      </Box>
    )
  }
}

const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #b3b3b3;
  padding-top: 6px;
`

const AttachmentsBoxDashed = styled(Box)`
  margin-top: 15px;
  width: 200px;
  height: 200px;
  background: #e4e4e4;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #a5a5a5;
`
