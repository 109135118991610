import React, { Component } from 'react'
import { Box, Modal, Spacer, KeyValueRow, Icons, File } from 'components'
import styled from '@emotion/styled'
import { QualitySheet } from 'api/types'
import { getDatetime } from 'shared/utils'

interface Props {
  onClose: () => void
  visible: boolean
  qualitySheet?: QualitySheet
  serie?: any
}

export default class QualitySheetModal extends Component<Props> {
  render() {
    const { onClose, visible, qualitySheet, serie } = this.props
    if (!qualitySheet) return <></>
    const {
      internalDesc,
      externalDesc,
      generalAspect,
      approvedDate,
      qualityCheckNote,
      attachments,
      testingRate,
      currentRate,
      inspector,
      lastModified,
      creationDate,
    } = qualitySheet

    const inspectorName = `${inspector?.name} ${inspector?.surname}`
    return (
      <Modal size="4xl" onClose={onClose} containerStyle={{ background: 'red' }} visible={visible}>
        <Box style={{ maxHeight: '70vh' }}>
          <Box vcenter row mb={30}>
            <Modal.Title>Scheda qualità</Modal.Title>
          </Box>
          <Box row>
            <KeyValueRow style={{ flex: 1 }} variant="grey" label="Ispettore" value={inspectorName ?? '---'} />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Last Modify"
              value={getDatetime(lastModified) ?? getDatetime(creationDate)}
            />
          </Box>
          <Spacer />
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Modello"
              value={serie.data.values.Modello ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Tag/Cal"
              value={serie.data.values['Tag/Cal'] ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Variante"
              value={serie.data.values.Variante ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Stagione"
              value={serie.data.values.Stagione ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Lavorante"
              value={serie.data.values.Lavorante ?? '---'}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Desc. Modello"
              value={serie.data.descriptions.Modello ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Desc. Taglia"
              value={serie.data.descriptions.Taglia ?? '---'}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Desc. Calzata"
              value={serie.data.descriptions.Calzata ?? '---'}
            />
            <Spacer />
            <KeyValueRow
              style={{ flex: 1 }}
              variant="grey"
              label="Desc. Variante"
              value={serie.data.descriptions.Variante ?? '---'}
            />
            <Spacer />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              valueStyle={{ whiteSpace: 'normal' }}
              variant="grey"
              label="Interno"
              value={internalDesc}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              valueStyle={{ whiteSpace: 'normal' }}
              variant="grey"
              label="Esterno"
              value={externalDesc}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              valueStyle={{ whiteSpace: 'normal' }}
              variant="grey"
              label="Aspetto generale"
              value={generalAspect}
            />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 2 }}
              variant="grey"
              label="Approvato il"
              image={approvedDate ? <Icons.CheckGreen /> : <Icons.CheckRed />}
              value={getDatetime(approvedDate) ?? '---'}
            />
            <Spacer />
            <KeyValueRow style={{ flex: 1 }} variant="grey" label="% Collaudo" value={`${testingRate}%`} />
            <Spacer />
            <KeyValueRow style={{ flex: 1 }} variant="grey" label="% Collaudo corrente" value={`${currentRate}%`} />
          </Box>
          <Spacer />
          <Box row>
            <KeyValueRow
              style={{ flex: 1 }}
              valueStyle={{ whiteSpace: 'normal' }}
              variant="grey"
              label="Note Approvazione"
              value={qualityCheckNote}
            />
          </Box>
          <Spacer />
          <Grid>
            {attachments.map((file) => (
              <>
                <File file={file} remote />
              </>
            ))}
          </Grid>
        </Box>
      </Modal>
    )
  }
}

const Grid = styled.div`
  display: grid;
  padding: 1px;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  grid-column-gap: 90px;
`
