import React, { Component } from 'react'
import RfidReader from 'shared/RfidReader'
import { showToast } from 'shared/utils'
import { T, __, __UP } from 'translations/i18n'
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { TmrItem, TmrTag } from 'api/types'
import * as Onde from 'assets/onde.json'
import Lottie from 'react-lottie'
import AppStore from 'AppStore'
import { Spacer } from './Various'

interface Props {
  onStart?: () => void
  onItemDecoded?: (item: TmrItem | { epc: string }, tag?: TmrTag) => void
  onClear?: () => void
  onError?: () => void
  icon?: string
  content?: JSX.Element
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  leftBottom?: boolean
  hideClear?: boolean
}

interface State {
  reading: boolean
  starting: boolean
  pendingTags: number
}

export default class AntennaButton extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    leftBottom: false,
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }
  constructor(props: Props) {
    super(props)
    RfidReader.initialize()
    RfidReader.onStartCallbackAntennaButton = this.onStartCallback
    RfidReader.onStopCallbackAntennaButton = this.onStopCallback
    RfidReader.onDecodedItemCallback = this.props.onItemDecoded
    RfidReader.onPendingTagsChangeAntennaButton = (pendingTags: number) => this.setState({ pendingTags })

    this.state = {
      reading: false,
      starting: false,
      pendingTags: 0,
    }
  }

  onStartCallback = () => this.setState({ reading: true })

  onStopCallback = () => this.setState({ reading: false })

  onClickAntennaButton = async () => {
    const { onError, onStart } = this.props
    if (RfidReader.isReading()) {
      RfidReader.stop()
    } else {
      this.setState({ starting: true })
      onStart?.()
      const started = await RfidReader.start(undefined, undefined, onError)
      if (!started && !onError && !AppStore.emulation) {
        showToast({
          title: __(T.error.error),
          description: __(T.error.workstation_could_not_be_started),
          status: 'error',
        })
      }
      this.setState({ starting: false })
    }
  }

  onClear = () => {
    RfidReader.clear()
    this.props.onClear?.()
  }

  renderIcon = () => {
    const style = {
      marginRight: 10,
      width: 36,
      height: 36,
    }
    const { starting, reading } = this.state
    if (starting) {
      return <Icons.Loader style={style} />
    }
    if (reading) {
      return <Icons.Pause style={style} />
    }
    return <Icons.Play style={style} />
  }

  render() {
    const { style, buttonStyle, onClear, hideClear } = this.props
    const { reading, pendingTags } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: (Onde as any).default,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    return (
      <>
        <Container row style={style}>
          <ButtonControl
            style={{ ...buttonStyle, backgroundColor: reading ? '#e4e4e4' : '#ceff00' }}
            row
            center
            onClick={() => this.onClickAntennaButton()}
          >
            {this.renderIcon()}
            {reading ? __UP(T.misc.stop) : __UP(T.misc.start)}
          </ButtonControl>
          {reading && (
            <div style={{ padding: '5px 10px' }}>
              <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
            </div>
          )}

          {!hideClear && onClear && !reading && (
            <>
              <Spacer />
              <ButtonClear style={buttonStyle} row center onClick={this.onClear || undefined}>
                <Icons.Retry style={{ marginRight: 10, width: 36, height: 36 }} />
                {__UP(T.misc.clear)}
              </ButtonClear>
            </>
          )}
        </Container>
        {pendingTags > 0 && (
          <PendingContainer row>
            Pending tags <div style={{ fontWeight: 900 }}>{pendingTags}</div>
          </PendingContainer>
        )}
      </>
    )
  }
}

const PendingContainer = styled(Box)`
  margin-top: 15px;
  background: #e4e4e4;
  border-radius: 50px;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 18px;
  justify-content: space-between;
`

const Container = styled(Box)`
  background-color: white;
  border: solid 2px #eeeeee;
  border-radius: 50px;
  padding: 10px;
`

const ButtonControl = styled(Box)`
  flex: 1;
  background-color: #ceff00;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: 900;
  font-size: 22px;
`

const ButtonClear = styled(ButtonControl)`
  background-color: #e4e4e4;
`
