import React, { Component } from 'react'
import {
  AntennaButton,
  Page,
  GroupedProductList,
  Spacer,
  TagCounter,
  Box,
  TextBox,
  Button,
  ItemsStatesRow,
} from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrZone } from 'api/types'
import { navigate, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast } from 'shared/utils'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import RfidReader from 'shared/RfidReader'
import { T, __ } from 'translations/i18n'
import Shipments from 'api/Shipments'
import AppStore from 'AppStore'
import { PageParams } from 'pages'
import Sounds from 'shared/Sounds'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import { inboundParcelBeforeConfirm, inboundParcelAfterConfirm } from 'pages/_extensions_/InboundExtensions'

interface Params extends PageParams {
  inboundZone: TmrZone
  parcelCode: string
}

interface MatchParams extends Params {
  configCode: string
  parcelCode: string
}

interface State {
  parcels: ShipmentParcel[]
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
}

export default class extends Component<{}, State> {
  unexpectedFound = false

  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  params: MatchParams = {
    inboundZone: AppStore.getZoneByType('INBOUND'),
    ...getMatchParams(this.props),
  }

  state: State = {
    parcels: [],
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
  }

  async componentDidMount() {
    try {
      if (!this.params.inboundZone) throw new Error(__(T.error.no_inbound_zones))
      if (!this.operation) throw new Error(__(T.error.no_operation_config))
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))

      const { checkListType, inboundShipmentParcels, groupedProducts } =
        await ShipmentProvider.fetchInboundShipmentParcels({ parcelCodes: [this.params.parcelCode] })

      await this.initRfidDevice(checkListType)
      this.setState({
        loading: false,
        checkListType,
        parcels: inboundShipmentParcels,
        groupedProducts,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  async initRfidDevice(checkListType) {
    await RfidReader.initialize()

    // if (checkListType === 'TAGS') {
    //   RfidReader.onTagReadCallback = this.handleReceivedTags
    // } else {
    RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
    RfidReader.setDecodeFunction(this.decodeFunction)
    // }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error(__(T.error.undefined_operation_config))

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (itemMap) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    const items: TmrItem[] = Object.values<TmrItem>(itemMap).filter((itm) => itm?.zone?.zoneType !== 'STOCK')

    ShipmentProvider.processInboundItemsStates(items, this.operation)

    ShipmentProvider.processItemForItemsChecklist(items, inboundShipmentParcels, groupedProducts)

    const counters = ShipmentProvider.getCounters(inboundShipmentParcels[0], this.state.checkListType)

    if (this.operation.hasChecklist !== 'no' && !this.unexpectedFound && counters.unexpected > 0) {
      this.unexpectedFound = true
      Sounds.error()
    }

    if (this.operation.hasChecklist !== 'no' && counters.detected === counters.expected && counters.unexpected === 0) {
      Sounds.success()
    }

    this.forceUpdate()
  }

  handleReceivedTags = () => {}

  clear = () => {
    const { parcels: inboundShipmentParcels } = this.state
    RfidReader.clear()
    this.unexpectedFound = false
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings(inboundShipmentParcels)
    this.setState({ groupedProducts })
  }

  confirmInbound = async () => {
    const { parcels: inboundShipmentParcels, checkListType } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(parcel, 'ITEMS')

    try {
      if (parcel?.detectedItems?.filter((item) => item.__processedStates?.includes('ERROR')).length)
        throw new Error(__(T.error.items_in_error_found))

      if (this.operation.hasChecklist === 'yes' && (detected < expected || detected > expected || unexpected > 0)) {
        const confirmed = await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.discrepancy_message))
        if (!confirmed) return
      }
      // extension point "beforeConfirm"
      await inboundParcelBeforeConfirm(inboundShipmentParcels!, this.operation)

      await ShipmentProvider.confirmInbound(
        inboundShipmentParcels,
        this.operation.id,
        this.params.inboundZone.id,
        checkListType
      )

      // extension point "afterConfirm"
      await inboundParcelAfterConfirm(parcel!, this.operation)

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.inbound_success),
        status: 'success',
      })
      this.navigateBack()
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  navigateBack = () => {
    const { parcels: inboundShipmentParcels } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined

    if (this.operation.inboundMode === 'shipment') {
      navigate('/inbound/:configCode/:shippingCode/parcels', {
        configCode: getMatchParams(this.props).configCode,
        shippingCode: parcel?.header?.shippingCode,
      })
      return
    }

    if (this.operation.hasChecklist === 'no') {
      navigate('/inbound/:configCode/create', { configCode: this.operation?.code })
    } else {
      navigate('/inbound/:configCode', { configCode: getMatchParams(this.props).configCode })
    }
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcels: inboundShipmentParcels, groupedProducts } = this.state
    ShipmentProvider.removeItemsForItemsChecklist([item], inboundShipmentParcels, groupedProducts)
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.forceUpdate()
  }

  render() {
    const { parcels: inboundShipmentParcels, groupedProducts, loading } = this.state
    const parcel = inboundShipmentParcels.length ? inboundShipmentParcels[0] : undefined
    const shippingCode = parcel?.header?.shippingCode
    const parcelCode = parcel?.header?.parcelCode
    const originPlace = parcel?.header?.originPlace?.description || parcel?.header?.originPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(parcel, 'ITEMS')
    const error = unexpected > 0 ? __(T.error.rfid_discrepancies_found) : ''
    const pageTitle = this.operation?.description ?? __(T.titles.inbound)

    const details = [
      { label: __(T.misc.shipment), value: shippingCode },
      { label: __(T.misc.parcel), value: parcelCode },
    ]

    if (this.operation.hasChecklist === 'yes') {
      details.push({ label: __(T.misc.origin), value: originPlace })
    }

    return (
      <Page
        title={pageTitle}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details,
        }}
        enableEmulation
      >
        <Page.Sidebar style={{ overflow: 'auto' }}>
          <Box flex style={{ overflow: 'auto' }}>
            <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
            <AntennaButton style={{ backgroundColor: 'white' }} onClear={this.clear} hideClear={detected === 0} />
            <Spacer />
            <TextBox text={error} type="error" />
          </Box>
          <Button title={__(T.misc.confirm_parcel)} onClick={this.confirmInbound} />
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
          />
          <GroupedProductList
            loading={loading}
            data={groupedProducts}
            noChecklist={this.operation.hasChecklist === 'no'}
          />
        </Page.Content>
      </Page>
    )
  }
}
