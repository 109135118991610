import { datadogRum } from '@datadog/browser-rum'
import { TmrUser } from 'api/types'

class DataDog {
  init() {
    datadogRum.init({
      applicationId: 'c1439c0d-81f5-4e75-ac48-9465d933412e',
      clientToken: 'pub111fa1fc918f5648635f99afedcd2afa',
      site: 'datadoghq.eu',
      env: process.env.REACT_APP_BACKEND_URL,
      service: 'ferragamo-rfid-station',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    })

    datadogRum.startSessionReplayRecording()
  }

  setUser(user: TmrUser, isInspector: boolean) {
    datadogRum.setUser({ id: user.id, name: user.username, place: user.place.code, isInspector })
  }

  removeUser() {
    datadogRum.removeUser()
  }

  addAction(actionName: string, data: any) {
    datadogRum.addAction(actionName, data)
  }

  addError(error, context) {
    datadogRum.addError(error, context)
  }
}
export default new DataDog()
