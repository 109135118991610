import React, { Component } from 'react'
import { Page, Tab, Placeholder, PlaceholderError, Box, SelectItemModal, ProductRow } from 'components'
import { getLocationState, navigate, navigateBack, getMatchParams } from 'shared/router'
import { askUserConfirmation, showToast, sleep } from 'shared/utils'
import Encodings from 'api/Encodings'
import { ProductionOrder, ProductionOrderRow, TmrTag, ReadIdentifierType, CustomProductionOrderDto } from 'api/types'
import RfidReader from 'shared/RfidReader'
import AppStore from 'AppStore'
import { T, __ } from 'translations/i18n'
import Sounds from 'shared/Sounds'
import RemoteConfig, { EncodingConfig } from 'shared/RemoteConfig'

import ProductionOrderInputBox from '../Encoding/ProductionOrderInputBox'
import ProductionOrderRowInputBox from '../Encoding/ProductionOrderRowInputBox'
import IdentifiersGrid from '../Encoding/IdentifiersGrid'
import CustomInputBox from '../Encoding/CustomInputBox'
import EncodingProvider, { EncodingType } from '../Encoding/EncodingProvider'

export type EncodingPageParams = {
  navigateBack?: boolean
  data?: any
  encodeFn?: EncodingType
  hideOptions?: boolean
  title?: string
  autoBackOnEncoded?: boolean
}

interface State {
  productionOrderValue?: string
  errorProductionOrder?: string
  identifiers: ReadIdentifierType[]
  errorProductionOrderRow?: any
  productionOrder?: ProductionOrder
  productionOrderRow?: ProductionOrderRow
  validateResponse?: any
  associateResponse?: any
  options: any
  readerError: boolean
  associating: boolean
  canReset: boolean
  locationState?: EncodingPageParams
  selectProduct?: boolean
  customProductionOrder?: CustomProductionOrderDto
  rowCode?: string
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default class Encoding extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<EncodingConfig>(getMatchParams(this.props).configCode)

  state: State = {
    identifiers: [],
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    readerError: false,
    associating: false,
    canReset: false,
    selectProduct: false,
    locationState: getLocationState(this.props),
  }

  encodingInitialType = RemoteConfig.getEncodingInitialType(this.operation)

  timer!: NodeJS.Timeout
  timerVerify!: NodeJS.Timeout

  ignoreEpcs: string[] = []

  inputProductionOrder = React.createRef<HTMLInputElement>()
  inputEAN = React.createRef<HTMLInputElement>()
  inputCustom = React.createRef<HTMLInputElement>()

  async componentDidMount() {
    if ((!AppStore.workstations || AppStore.workstations.length === 0) && !AppStore.emulation) {
      navigate('/')
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.messages.no_workstation_selected),
        status: 'error',
      })
      return
    }

    await RfidReader.initialize()
    this.stopReader()
    switch (this.encodingInitialType) {
      case 'ean':
        this.inputEAN?.current?.focus()
        break
      case 'custom':
        this.inputProductionOrder?.current?.focus()
        break
      case 'order':
      default:
        this.inputProductionOrder?.current?.focus()
        break
    }
  }

  componentWillUnmount() {
    RfidReader.stop()
    RfidReader.clear()
  }

  stopReader = async () => {
    RfidReader.clear()
    await RfidReader.stop()
  }

  resetState = () =>
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      productionOrderValue: '',
      productionOrder: undefined,
      identifiers: [],
      rowCode: undefined,
    })

  clearProduct = () => {
    this.ignoreEpcs = []
    this.stopReader()
    if (this.encodingInitialType === 'custom') {
      this.resetState()
      return
    }
    this.setState({
      errorProductionOrder: undefined,
      errorProductionOrderRow: undefined,
      productionOrderValue: '',
      productionOrderRow: undefined,
      associateResponse: undefined,
      validateResponse: undefined,
      identifiers: [],
      rowCode: undefined,
    })
  }

  clearProductionOrder = () => {
    this.stopReader()
    this.clearProduct()
    this.setState(
      {
        errorProductionOrderRow: undefined,
        errorProductionOrder: undefined,
        productionOrderValue: '',
        productionOrder: undefined,
        rowCode: undefined,
      },
      () => {
        this.inputProductionOrder?.current?.focus()
      }
    )
  }

  resetReading = () => {
    const { productionOrderRow } = this.state
    const idfs: any = []
    productionOrderRow?.product.itemConfiguration.identifiers
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1
        }
        if (b.type > a.type) {
          return -1
        }
        return 0
      })
      .forEach((idf) => {
        for (let index = 0; index < idf.amount; index++) {
          idfs.push({ status: 'waiting', type: idf.type })
        }
      })
    this.setState({
      identifiers: idfs,
      validateResponse: undefined,
      associateResponse: undefined,
      associating: false,
      canReset: false,
      rowCode: undefined,
    })
    RfidReader.clear()
    if (!RfidReader.isReading()) RfidReader.start(this.onTagReadCallback)
  }

  verify = async (idfs: ReadIdentifierType[]) => {
    const { customProductionOrder, productionOrderRow } = this.state
    if (idfs.length === 0 || !productionOrderRow) return

    clearTimeout(this.timerVerify)
    this.timerVerify = setTimeout(async () => {
      try {
        const res = await Encodings.validate(idfs, {
          productionOrderId: customProductionOrder?.id,
          productId: productionOrderRow?.product.id,
          identifiers: idfs.filter((idf) => !!idf.code) as any,
          configurationId: this.operation.id,
        })
        idfs = idfs.filter((idf) => idf.status !== 'remove')
        productionOrderRow?.product.itemConfiguration.identifiers
          .sort((a, b) => {
            if (a.type > b.type) {
              return 1
            }
            if (b.type > a.type) {
              return -1
            }
            return 0
          })
          .forEach((idf) => {
            for (let index = idfs.filter((id) => id.type === idf.type).length; index < idf.amount; index++) {
              idfs.push({ status: 'waiting', type: idf.type })
            }
          })

        if (res.success) {
          idfs.forEach((idf) => {
            idf.status = 'confirmed'
          })
        }

        this.setState({ identifiers: idfs, validateResponse: res })

        if (!res.success) {
          clearTimeout(this.timer)
          this.setState({ associateResponse: undefined })
          return
        }

        this.associate(idfs, customProductionOrder, productionOrderRow)
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Generic error',
          status: 'error',
        })
      }
    }, 500)
  }

  associate = (idfs, customProductionOrder, productionOrderRow) => {
    this.setState({ associating: true })

    //Start timer to delay the association (in case of multiple reading)
    this.timer = setTimeout(async () => {
      try {
        await RfidReader.stop()
        if (this.operation.enableWrite) {
          await EncodingProvider.writeUHFTags(this.operation, idfs, productionOrderRow, (nextEpc) => {
            this.ignoreEpcs.push(nextEpc)
          })
        }
        const associateRes = await EncodingProvider.encode(
          {
            productionOrderId: customProductionOrder?.id,
            productId: productionOrderRow?.product?.id,
            identifiers: idfs.filter((idf) => idf.code) as any,
            configurationId: this.operation.id,
          },
          this.state.locationState?.encodeFn,
          this.state.locationState?.data
        )
        this.ignoreEpcs = idfs.map((idf) => idf.code ?? '')
        this.setState({ associateResponse: associateRes, associating: false })

        await sleep(500)
        if (associateRes?.success) {
          Sounds.success()
          await AppStore.increaseDailyItems()
        } else {
          Sounds.fail()
          throw new Error(
            associateRes?.errors?.[0].errorCode ??
              `Generic error, probably because encodeFn ${this.state.locationState?.encodeFn} is not implemented in EncodingProvider.encode`
          )
        }
        if (this.state.locationState?.autoBackOnEncoded) {
          navigateBack()
        }
        const EANCode = productionOrderRow?.product?.code || ''
        if (this.encodingInitialType === 'custom') {
          this.clearProduct()
          return
        }
        this.searchProduct(EANCode)
      } catch (error) {
        if (error && error.message) {
          showToast({
            title: __(T.error.error),
            description: error?.message ?? error,
            status: 'error',
          })
        }
        this.setState({ associateResponse: { success: false }, associating: false, canReset: true })
      }
    }, 1000)
  }

  onTagReadCallback = async (tag: TmrTag) => {
    try {
      const { identifiers } = this.state
      const idfs = [...identifiers]

      EncodingProvider.onTagRead(tag, idfs, this.ignoreEpcs)

      this.setState({ identifiers: idfs })

      this.verify(idfs)
    } catch (err) {
      //excluded epc
    }
  }

  searchProduct = async (productCode: string) => {
    const { productionOrderValue } = this.state

    try {
      this.setState({ readerError: false, errorProductionOrder: undefined })

      const { idfs, data } = await Encodings.getProductionOrderRows(this.operation, this.encodingInitialType, {
        orderCode: productionOrderValue,
        identifier: productCode,
        productCode,
      })

      if (this.encodingInitialType === 'custom' && data.encoded! >= data.quantity!) {
        throw new Error('ENCODING_ERROR.CERTILOGO_ALREADY_ENCODED')
      }

      this.startEncodingProduct(idfs, data, productCode)
    } catch (err) {
      Sounds.error()
      this.setState({
        errorProductionOrderRow: EncodingProvider.getEncodingMessageError(err, productCode, this.encodingInitialType),
      })
    }
  }

  startEncodingProduct = async (idfs, data, productCode) => {
    this.setState({ errorProductionOrderRow: undefined })
    Sounds.tap()
    await RfidReader.initialize()
    await RfidReader.stop()
    const newState = {
      identifiers: idfs,
      productionOrderRow: data,
      productionOrder: data.order,
      readerError: false,
      associateResponse: undefined,
      validateResponse: undefined,
      productionOrderRowValue: productCode,
    } as any
    const antennaStarted = await RfidReader.start(this.onTagReadCallback)
    if (antennaStarted) {
      this.setState(newState)
    } else if (AppStore.emulation) {
      this.setState(newState)
      showToast({
        title: __(T.misc.info),
        description: 'Emulation enabled, cannot connect to workstations',
        status: 'info',
      })
    }
  }

  searchProductionOrder = async (value: string) => {
    try {
      const data = await Encodings.getProductionOrder(this.operation, value)
      if (!data) throw new Error()
      Sounds.tap()
      this.setState({
        errorProductionOrder: undefined,
        productionOrderValue: value,
        productionOrder: data,
      })
      await sleep(100)
      this.inputEAN?.current?.focus()
    } catch (error) {
      this.setState({ errorProductionOrder: __(T.error.production_order_not_found, { code: value }) })
    }
  }

  onResetDailyItems = async () => {
    if (await askUserConfirmation(__(T.titles.reset_daily_items), __(T.messages.are_you_sure_to_reset_daily_items))) {
      AppStore.resetDailyItems()
      this.forceUpdate()
    }
  }

  onCustomCodeSearch = async (rowCode: string) => {
    try {
      const customProductionOrder = await Encodings.getCustomProductionOrder(rowCode, this.operation)
      if (!customProductionOrder.rows || customProductionOrder.rows.length === 0) {
        return this.setState({ errorProductionOrder: 'Empty production order row' })
      }
      if (customProductionOrder.rows.length === 1) {
        await this.setState({ customProductionOrder })
        this.onSelectedCustomProduct([customProductionOrder.rows[0]])
      } else {
        this.setState({ rowCode, customProductionOrder, selectProduct: true })
      }
    } catch (error) {
      if (error?.message) {
        return showToast({ status: 'error', title: __(T.error.error), description: error.message })
      }
      this.setState({ errorProductionOrder: __(T.error.production_order_not_found, { rowCode }) })
    }
    return undefined
  }

  onSelectedCustomProduct = (orderRow: ProductionOrderRow[]) => {
    const { idfs, data } = Encodings.getIdentifiersProducts(orderRow[0])
    this.startEncodingProduct(idfs, data, orderRow[0].product.code)
    this.setState({ selectProduct: false, rowCode: orderRow[0].rowCode })
  }

  render() {
    const {
      productionOrder,
      errorProductionOrder,
      productionOrderRow,
      errorProductionOrderRow,
      productionOrderValue,
      options,
      identifiers,
      validateResponse,
      associateResponse,
      readerError,
      associating,
      canReset,
      locationState,
      selectProduct,
      customProductionOrder,
      rowCode,
    } = this.state

    const title = locationState?.title ?? this.operation.description ?? 'Encoding'

    const headerRight = !locationState?.hideOptions ? (
      <Tab options={options} onOptionSelected={(opt) => EncodingProvider.getOptionEncodingPage(opt, this.operation)} />
    ) : undefined

    return (
      <Page
        title={title}
        header={{
          details: [
            {
              label: __(T.misc.items),
              value: `${AppStore.dailyItems}`,
              onPress: AppStore.dailyItems > 0 ? this.onResetDailyItems : undefined,
            },
          ],
        }}
        headerRight={headerRight}
        enableEmulation
        onBackPress={locationState?.navigateBack ? navigateBack : undefined}
      >
        <Page.Sidebar>
          {(this.encodingInitialType === 'order' || productionOrder) && (
            <ProductionOrderInputBox
              productionOrder={productionOrder}
              productionOrderValue={productionOrderValue}
              errorProductionOrder={errorProductionOrder}
              inputProductionOrder={this.inputProductionOrder}
              clearProductionOrder={this.clearProductionOrder}
              searchProductionOrder={this.searchProductionOrder}
            />
          )}
          {((productionOrder && this.encodingInitialType === 'order') || this.encodingInitialType === 'ean') && (
            <ProductionOrderRowInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputEAN={this.inputEAN}
              searchProduct={this.searchProduct}
              clearProduct={this.clearProduct}
              searchProductionOrderSetting={this.encodingInitialType === 'order'}
            />
          )}

          {this.encodingInitialType === 'custom' && (
            <CustomInputBox
              productionOrderRow={productionOrderRow}
              errorProductionOrderRow={errorProductionOrderRow}
              inputCustom={this.inputCustom}
              searchProduct={this.onCustomCodeSearch}
              clearProduct={this.clearProduct}
              searchProductionOrderSetting={this.encodingInitialType === 'custom'}
            />
          )}
        </Page.Sidebar>
        <Page.Content>
          {readerError && !AppStore.emulation && (
            <Box center>
              <PlaceholderError>{__(T.misc.unable_to_connect_to_workstation)}</PlaceholderError>
            </Box>
          )}
          {productionOrderRow && (
            <IdentifiersGrid
              productionOrderRow={productionOrderRow}
              identifiers={
                identifiers.length > 0
                  ? [
                      ...identifiers.filter((i) => i.type === 'UHFTag' || i.type === 'NFCTag'),
                      //Add associating operation box
                      EncodingProvider.getAssociationStatus(
                        associateResponse,
                        associating,
                        validateResponse,
                        this.operation.enableWrite
                      ),
                    ]
                  : []
              }
              onTagReadCallback={this.onTagReadCallback}
              removeErrorTag={EncodingProvider.checkErrorTag(identifiers) || canReset ? this.resetReading : undefined}
              placeholder={
                identifiers.length === 0 && (
                  <Placeholder style={{ width: 370 }}>{__(T.misc.enter_the_necessary_fields)}</Placeholder>
                )
              }
            />
          )}
        </Page.Content>
        {selectProduct && (
          <SelectItemModal
            title={__(T.titles.select_product)}
            visible={selectProduct}
            searchable
            selected={[customProductionOrder?.rows.find((row) => row.rowCode === rowCode)]}
            options={customProductionOrder?.rows ?? []}
            onSelect={this.onSelectedCustomProduct}
            field="product.code"
            onClose={() => this.setState({ selectProduct: false })}
            customRowRender={(row: ProductionOrderRow, selected: boolean) => {
              const { product } = row
              product.encoded = row.encoded
              product.quantity = row.quantity
              return <ProductRow product={product} selected={selected} />
            }}
          />
        )}
      </Page>
    )
  }
}
