import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Button, ItemsModal, Box, Icons } from 'components'

const Counter = styled.div`
  font-size: 150px;
  font-weight: bold;
  text-align: center;
  min-width: 200px;
`

const SubTitle = styled.div`
  font-size: 20px;
`

const InfoImage = styled((props) => <Icons.Info {...props} />)`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
`

const Container = styled.div`
  position: relative;
`

interface Props {
  tags: any[]
  expected?: any[]
  onClearPress?: () => void
  onDetailClick?: () => void
}

interface State {
  detailVisible: boolean
}

export default class TagInfoCounter extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { detailVisible: false }
  }

  onDetailClick = () => {
    const { tags, onDetailClick } = this.props
    if (tags.length === 0) return

    if (onDetailClick) {
      onDetailClick()
      return
    }

    this.setState({ detailVisible: true })
  }

  onCloseModal = () => this.setState({ detailVisible: false })

  render() {
    const { tags, onClearPress, expected } = this.props
    const { detailVisible } = this.state
    const counter = expected ? `${tags.length}/${expected.length}` : tags.length
    return (
      <Container>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div style={{ cursor: tags.length > 0 ? 'pointer' : 'unset' }} onClick={this.onDetailClick}>
          {tags.length > 0 && <InfoImage />}
          <Counter>{counter}</Counter>
        </div>
        <Box flex={0} center>
          {tags.length > 0 ? (
            onClearPress && (
              <Button variant="secondary" onClick={onClearPress} style={{ width: 200 }}>
                CLEAR
              </Button>
            )
          ) : (
            <SubTitle>Leggi uno o più item</SubTitle>
          )}
        </Box>
        {detailVisible && <ItemsModal items={tags} onClose={this.onCloseModal} />}
      </Container>
    )
  }
}
