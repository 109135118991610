import {
  ShipmentParcel,
  ShipmentParcelFilter,
  ShippingParcelDetail,
  ShipmentParcelPreview,
  ShippingParcelCreateRequest,
  ShippingConfirmRequest,
  ShipmentPreview,
} from './types'
import api, { responseErrorCheck } from './api'

export default class OutboundShipments {
  static endpoint = '/shipping/outbound'

  static shipmentCounters(
    filters?: ShipmentParcelFilter,
    requestConfig = undefined
  ): Promise<ShipmentPreview[] | undefined> {
    return api
      .post<ShipmentPreview[]>(`${this.endpoint}/shipment/counters`, filters ?? {}, requestConfig)
      .then(responseErrorCheck)
      .then((data) => data?.sort((a, b) => (b.creationDate ?? 0) - (a.creationDate ?? 0)))
  }

  static parcelCounters(
    filters?: ShipmentParcelFilter,
    requestConfig = undefined
  ): Promise<ShipmentParcelPreview[] | undefined> {
    return api
      .post<ShipmentParcelPreview[]>(`${this.endpoint}/parcel/counters`, filters ?? {}, requestConfig)
      .then(responseErrorCheck)
      .then((data) => data?.sort((a, b) => (b.header.parcelCreationDate ?? 0) - (a.header.parcelCreationDate ?? 0)))
  }

  static parcelDetail(filters: ShipmentParcelFilter, requestConfig = undefined): Promise<ShipmentParcel[] | undefined> {
    return api.post<ShipmentParcel[]>(`${this.endpoint}/parcel/detail`, filters, requestConfig).then(responseErrorCheck)
  }

  static updateReadings(parcelCode, data: ShippingParcelDetail, requestConfig = undefined) {
    return api
      .post(`${this.endpoint}/parcel/updateReadings`, { ...data, parcelCode }, requestConfig)
      .then(responseErrorCheck)
  }

  static confirmParcel(parcelCode, data: ShippingConfirmRequest = {}, requestConfig = undefined) {
    return api.post(`${this.endpoint}/parcel/confirm`, { ...data, parcelCode }, requestConfig).then(responseErrorCheck)
  }

  static createParcel(data: ShippingParcelCreateRequest, requestConfig = undefined) {
    return api
      .post<ShipmentParcelPreview>(`${this.endpoint}/parcel/start`, data, requestConfig)
      .then(responseErrorCheck)
  }

  static confirmShipment(data: { shippingCode: string; configurationId: string }, requestConfig = undefined) {
    return api
      .post<ShipmentParcelPreview>(`${this.endpoint}/shipment/confirm`, data, requestConfig)
      .then(responseErrorCheck)
  }
}
