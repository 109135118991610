import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { BoxProps } from './Box'
import { Spacer } from './Various'

const colors = {
  success: '#18C248',
  warning: '#FF9432',
  error: '#ff3c3d',
  info: '#18a0c2',
}

type Variant = 'error' | 'warning' | 'success' | 'info'

interface Props extends BoxProps {
  title?: string
  text?: string
  type?: Variant
}

export default function TextBox({ title, text, type, ...rest }: Props) {
  if (!text && !title) return null

  return (
    <Container center type={type} p={20} {...rest}>
      {title && (
        <>
          <p style={{ fontWeight: 700, fontSize: 30 }}>{title}</p>
          <Spacer />
        </>
      )}
      {text && <p style={{ fontWeight: 500 }}>{text}</p>}
    </Container>
  )
}

const Container = styled(Box)<{ type?: Variant }>`
  border: 4px solid ${({ type, theme }) => (type ? colors[type] : theme.background1)};
  font-size: 25px;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
  * {
    color: ${({ type, theme }) => (type ? colors[type] : theme.background1)};
  }
`
