import AppStore from 'AppStore'
import config from 'config/config'
import api, { responseErrorCheck } from './api'
import { Product } from './types'

export default class Products {
  static endpoint = 'products'

  static get(code: string): any {
    return api
      .get<any[]>(`products?code=${code}`)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getProductsByCodes(upcs: string[]): Promise<Product[]> {
    return api
      .post<Product | any>(`${this.endpoint}/advancedSearch`, {
        codes: upcs,
        limit: 50000,
      })
      .then(responseErrorCheck)
  }

  static getImageUrl(code?: string): string {
    if (!code) {
      return ''
    }
    const backendBaseUrl =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint
    const imageUrl = `${backendBaseUrl}${Products.endpoint}/image/${code}/full/full/0/default.jpg?x-tmr-token=${AppStore.authToken}`
    return imageUrl
  }
}
