import React from 'react'
import { TmrItem } from 'api/types'
import { Skeleton } from '@chakra-ui/react'
import { ItemRow, EmptyListMessage } from 'components'
import { T, __ } from 'translations/i18n'
import { Spacer } from './Various'

interface Props {
  items: TmrItem[]
  emptyMessage?: string | JSX.Element
  style?: React.CSSProperties
  loading?: boolean
  onInfoRowPress?: (tag: TmrItem) => void
  onDeleteRowCallback?: (item: TmrItem) => void
}

export default function ItemList({
  items,
  emptyMessage = <span style={{ whiteSpace: 'break-spaces' }}>{__(T.messages.press_start_to_read)}</span>,
  style,
  loading = false,
  onInfoRowPress,
  onDeleteRowCallback,
}: Props) {
  if (!items) return null

  return (
    <Skeleton
      isLoaded={!loading}
      style={items?.length !== 0 ? style : { display: 'flex', flex: 1, justifyContent: 'center' }}
    >
      {items.length > 0 &&
        items.map((item, index) => (
          <>
            <ItemRow
              key={item.id}
              id={index}
              item={item}
              onInfoPress={item.product && item.itemIdentifiers?.length > 0 ? onInfoRowPress : undefined}
              onDeleteCallback={onDeleteRowCallback}
            />
            <Spacer />
          </>
        ))}
      {(!items || items.length === 0) && <EmptyListMessage center>{emptyMessage}</EmptyListMessage>}
    </Skeleton>
  )
}
