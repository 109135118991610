// import Encodings from 'api/Encodings'
import OperationConfigurations from 'api/OperationConfigurations'
import { navigate } from 'shared/router'
import { getListFromCommaSeparatedString } from './utils'

// TYPES //////////////////////////////////////////////////////////////////////////////////////////////////////

export type OperationConfigType = 'inbound' | 'outbound' | 'encoding'
export type OperationConfigClient = 'frontend' | 'mobile' | 'station'

export type EncodingInitialType = 'identifier' | 'order' | 'custom' | 'product' | 'ean'

interface OperationConfig {
  id: string
  code: string
  description: string
  operationType: OperationConfigType
  clients: OperationConfigClient[]
  attributes: Record<string, any>
}

interface ShippingConfig extends OperationConfig {
  readingMode: 'rfid' | 'mixed' | 'barcode' // tmr.operation.config.reading.mode
  canConfirmWithMissing: 'no' | 'yes' | 'withWarning' // tmr.operation.config.missing.confirm
  canConfirmWithOverQuantity: 'no' | 'yes' | 'withWarning' // tmr.operation.config.overqty.confirm
  canConfirmWithUnexpected: 'no' | 'yes' | 'withWarning' // tmr.operation.config.unexpected.confirm
  canForceConfirm: 'no' | 'yes' | 'withExpectedReadings' // tmr.operation.config.force.confirm
  removeMode: 'sku' | 'rfid' | 'epc' | 'identifier' | 'none' // tmr.operation.config.remove.mode
  itemWarningStates: string[] // tmr.operation.config.item.warning.states
  itemErrorStates: string[] // tmr.operation.config.item.error.states
  itemIgnoreStates: string[] // tmr.operation.config.item.ignore.states
  itemRequireStates: string[] // tmr.operation.config.item.require.states
  icon?: string
  hasChecklist: 'no' | 'yes'
}

export interface InboundConfig extends ShippingConfig {
  inboundMode?: 'parcelByParcel' | 'multiParcel' | 'shipment' // tmr.operation.config.inbound.mode
}

export interface OutboundConfig extends ShippingConfig {
  outboundMode?: 'parcelByParcel' | 'shipment' // tmr.operation.config.outbound.mode
}

export interface EncodingConfig extends OperationConfig {
  initialType?: 'order' | 'custom' | 'identifier' | 'product' // tmr.logistics.encoding.initial.type
  validateProductionOrder?: boolean // tmr.logistics.encoding.productionorder.validate
  identifierValidate?: boolean // tmr.logistics.encoding.identifiers.exist.validate
  enableWrite?: boolean // tmr.logistics.encoding.write.enable
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const fakeEncodingOperation = 'FAKE_ENCODING_OPERATION'

export class RemoteConfigBase {
  private operations: OperationConfig[] = []

  async load(): Promise<void> {
    const operations = await OperationConfigurations.search<OperationConfig>({ client: 'station' })
    this.operations = operations.map(({ operationType, ...rest }) => ({
      operationType: String(operationType).toLowerCase() as OperationConfigType,
      ...rest,
    }))
    // Encoding settings removed from 4.53.0
    // const encodingSettings = await Encodings.settings()
    // if (encodingSettings) {
    //   const encodingOpConfig: OperationConfig = {
    //     id: fakeEncodingOperation,
    //     code: fakeEncodingOperation,
    //     description: fakeEncodingOperation,
    //     clients: ['station'],
    //     operationType: 'encoding',
    //     attributes: encodingSettings,
    //   }
    //   this.operations.push(encodingOpConfig)
    // }
  }

  getAllShippingOperations(): ShippingConfig[] {
    return this.operations
      .filter((op) => op.operationType === 'inbound' || op.operationType === 'outbound')
      .map((op) => this.getOperationConfigByCode(op.code) as ShippingConfig)
  }

  getAllEncodingOperations(): EncodingConfig[] {
    return this.operations
      .filter((op) => op.operationType === 'encoding')
      .map((op) => this.getOperationConfigByCode(op.code) as EncodingConfig)
  }

  getOperationConfig<T>(code: string): T {
    return this.getOperationConfigByCode(code) as any
  }

  private getOperationConfigByCode(code: string) {
    const opConfig = this.operations.find((o) => o.code === code)
    if (!opConfig) {
      navigate('/') //TODO navigate messa per retrocompatibilità con AppStore, analizzare come migliorare
      throw new Error(`Unable to find OperationConfig for code: ${code}`)
    }

    if (opConfig.operationType === 'outbound') {
      const mapping: OutboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        outboundMode: opConfig.attributes['tmr.operation.config.outbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'sku',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'no',
      }
      return mapping
    }

    if (opConfig.operationType === 'inbound') {
      const mapping: InboundConfig = {
        ...opConfig,
        readingMode: opConfig.attributes['tmr.operation.config.reading.mode'] ?? 'rfid',
        inboundMode: opConfig.attributes['tmr.operation.config.inbound.mode'] ?? 'parcelByParcel',
        canConfirmWithMissing: opConfig.attributes['tmr.operation.config.missing.confirm'] ?? 'withWarning',
        canConfirmWithOverQuantity: opConfig.attributes['tmr.operation.config.overqty.confirm'] ?? 'withWarning',
        canConfirmWithUnexpected: opConfig.attributes['tmr.operation.config.unexpected.confirm'] ?? 'withWarning',
        removeMode: opConfig.attributes['tmr.operation.config.remove.mode'] ?? 'sku',
        canForceConfirm: opConfig.attributes['tmr.operation.config.force.confirm'] ?? 'no',
        itemWarningStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.warning.states']
        ),
        itemErrorStates: getListFromCommaSeparatedString(opConfig.attributes['tmr.operation.config.item.error.states']),
        itemIgnoreStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.ignore.states']
        ),
        itemRequireStates: getListFromCommaSeparatedString(
          opConfig.attributes['tmr.operation.config.item.require.states']
        ),
        icon: opConfig.attributes['tmr.operation.config.custom.icon'],
        hasChecklist: opConfig.attributes['tmr.operation.config.hasChecklist'] ?? 'yes',
      }
      return mapping
    }

    if (opConfig.operationType === 'encoding') {
      const mapping: EncodingConfig = {
        ...opConfig,
        initialType: opConfig.attributes['tmr.logistics.encoding.initial.type']?.toLowerCase(),
        identifierValidate:
          opConfig.attributes['tmr.logistics.encoding.identifiers.exist.validate']?.toLowerCase() === 'true',
        validateProductionOrder: opConfig.attributes['tmr.logistics.encoding.productionorder.validate']
          ? opConfig.attributes['tmr.logistics.encoding.productionorder.validate']?.toLowerCase() === 'true'
          : true,
        enableWrite: opConfig.attributes['tmr.logistics.encoding.write.enable']
          ? opConfig.attributes['tmr.logistics.encoding.write.enable']?.toLowerCase() === 'true'
          : false,
      }
      return mapping
    }

    throw new Error(`Undefined operationType for OperationConfig with code: ${code}`)
  }

  getEncodingInitialType(encodingConfig: EncodingConfig): EncodingInitialType {
    let initialType: EncodingInitialType = 'order'
    if (encodingConfig.validateProductionOrder === false) initialType = 'ean'
    // if (encodingConfig.initialType === 'custom') initialType = 'certilogo'

    return encodingConfig.initialType ?? initialType
  }
}

export default new RemoteConfigBase()
