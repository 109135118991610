import React, { Component } from 'react'
import { ShipmentPreview } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { getDatetime } from 'shared/utils'
import { InboundConfig, OutboundConfig } from 'shared/RemoteConfig'

interface Props {
  shipment: ShipmentPreview
  onRowClick: (shipment: ShipmentPreview) => void
  operation: InboundConfig | OutboundConfig
}
export default class ShipmentRow extends Component<Props> {
  render() {
    const { onRowClick, shipment, operation } = this.props

    return (
      <RowItem onClick={() => onRowClick(shipment)}>
        <List.Cell label={__(T.misc.shipment)} value={shipment.shippingCode} />
        {operation.operationType === 'outbound' && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.destination)}
            value={shipment.destinationPlace?.description}
          />
        )}
        {operation.operationType === 'inbound' && (
          <List.Cell style={{ width: 240 }} label={__(T.misc.origin)} value={shipment.originPlace?.description} />
        )}
        <List.Cell style={{ width: 240 }} label={__(T.misc.creation_date)} value={getDatetime(shipment.creationDate)} />
        {operation.hasChecklist !== 'no' && (
          <Box width={100}>
            <SmallCounter detected={shipment.confirmedParcels} expected={shipment.totalParcels}>
              {`${shipment.confirmedParcels} / ${shipment.totalParcels}`}
            </SmallCounter>
          </Box>
        )}
        {operation.hasChecklist === 'no' && (
          <Box width={100}>
            <SmallCounter>{shipment.totalParcels}</SmallCounter>
          </Box>
        )}
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
