/* eslint-disable react/prop-types */
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import ParseDate from 'date-fns/parse'
import { Input } from 'components'

registerLocale('it', it)

interface Props {
  index: number
  label: string
  name: string
  handleChange: (name: string, value: any) => void
  value: any
  type: 'date' | 'datetime'
}

const InputDate = (props: Props) => {
  const CustomInput = (p: any) => (
    <Input
      key={`input${props.index.toString()}`}
      onFocus={p.onClick}
      onChange={(date) => {
        if (date === '') p.handleChange(p.sName, undefined)
        const regex = /^\d{2}([-])\d{2}\1\d{4}$/
        if (regex.test(date)) {
          p.handleChange(p.sName, ParseDate(date, 'dd-MM-yyyy', new Date()))
        }
      }}
      defaultValue={p.value}
      value={p.value}
      id={p.sName}
      type="text"
      placeholder={p.placeholder ?? ''}
      image={p.image}
      label={p.label}
    />
  )

  return (
    <DatePicker
      selected={props.value}
      onChange={(date) => props.handleChange(props.name, date)}
      showTimeSelect={props.type === 'datetime'}
      dateFormat={props.type === 'date' ? 'dd-MM-yyyy' : 'dd-MM-yyyy hh:mm'}
      locale="it"
      customInput={<CustomInput {...props} sName={props.name} />}
    />
  )
}

export default InputDate
