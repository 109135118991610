import React, { Component } from 'react'
import Hotkeys from 'react-hot-keys'
import styled from '@emotion/styled'
import RfidReader from 'shared/RfidReader'
import { navigate } from 'shared/router'
import { MotionStyle } from 'framer-motion'
import Header, { HeaderAction, HeaderDetail } from './Header'
import EmulationModal from './modals/EmulationModal'
import Box from './Box'

export type Header = { details?: HeaderDetail[]; actions?: HeaderAction[] }

interface Props {
  title: string
  onBackPress?: () => void
  hideTopBar?: boolean
  topBarRight?: JSX.Element
  loading?: boolean
  header?: Header | null
  headerRight?: JSX.Element
  enableEmulation?: boolean
  emulationFunction?: (epcs: string[]) => void
}

interface State {
  showEmulationModal: boolean
}

export default class Page extends Component<Props, State> {
  static Title = styled(Box)`
    font-weight: bold;
    font-size: 30px;
  `

  static TopBar = ({ children }: { children: React.ReactNode }) => {
    return (
      <Box mb={40} pv={10} row>
        {children}
      </Box>
    )
  }

  static Sidebar = ({
    style,
    width = 470,
    children,
  }: {
    style?: MotionStyle
    width?: number
    children: React.ReactNode
  }) => {
    return (
      <Box style={style} width={width} bgGrey>
        <Box flex p={40} pt={30}>
          {children}
        </Box>
      </Box>
    )
  }

  static Content = ({ children, bgGrey }: { children: React.ReactNode; bgGrey?: boolean }) => {
    return (
      <Box
        flex
        style={{ overflowY: 'auto', scrollbarWidth: 'none', boxShadow: '0px 2px 10px rgba(0,0,0,0.05)' }}
        ph={30}
        pv={40}
        bgGrey={bgGrey}
      >
        {children}
      </Box>
    )
  }

  state: State = {
    showEmulationModal: false,
  }
  componentDidMount() {
    const title = `Stylewhere | RFID Station - ${this.props.title}`
    if (title !== document.title) document.title = title
  }

  onEmulationConfirm = (input?: string) => {
    this.setState({ showEmulationModal: false })
    if (!input) return
    const codes: string[] = String(input).replace(/ /g, '').split(',') ?? []
    if (this.props.emulationFunction) {
      this.props.emulationFunction(codes)
      return
    }
    RfidReader.emulateTagAuto(codes.map((code) => ({ epc: code })))
  }

  onKeyDown = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ showEmulationModal: !this.state.showEmulationModal })
  }

  render() {
    const { title, onBackPress, header, headerRight, children, loading, enableEmulation } = this.props
    const { showEmulationModal } = this.state

    return (
      <>
        <Hotkeys
          keyName="command+e,ctrl+m,ctrl+shift+e,command+shift+e"
          onKeyDown={this.onKeyDown}
          disabled={!enableEmulation}
        >
          <PageContainer>
            {header !== null && (
              <Header
                title={title}
                actions={header?.actions}
                details={header?.details}
                onBackPress={onBackPress ?? (() => navigate('/'))}
                loading={loading}
              >
                {headerRight}
              </Header>
            )}
            <Box flex row style={{ overflow: 'hidden' }}>
              {children}
            </Box>
          </PageContainer>

          {enableEmulation && showEmulationModal && (
            <EmulationModal
              title="Emulation Modal"
              onClose={() => {
                this.setState({ showEmulationModal: false })
              }}
              onConfirm={this.onEmulationConfirm}
            />
          )}
        </Hotkeys>
      </>
    )
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
`
