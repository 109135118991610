import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Image, Box, RoundedLabel, Icons } from 'components'
import { CustomTmrItem, TmrItem } from 'api/types'
import Products from 'api/Products'
import { T, __ } from 'translations/i18n'
import { Spacer } from './Various'
import { CustomItemInfoModal } from './modals'

interface Props {
  id: any
  item: CustomTmrItem
  onInfoPress?: (item: CustomTmrItem) => void
  onDeleteCallback?: (item: CustomTmrItem) => void
}

interface State {
  visibleItemModal: boolean
}

export default class CustomItemRow extends Component<Props, State> {
  state: State = {
    visibleItemModal: false,
  }

  openItemInfoModal = () => this.setState({ visibleItemModal: true })
  closeItemInfoModal = () => this.setState({ visibleItemModal: false })

  onRowPress = () => {
    const { onInfoPress, item } = this.props
    if (onInfoPress) return () => onInfoPress(item)
    if (!item.id) return undefined
    if (!onInfoPress) return this.openItemInfoModal
    return undefined
  }

  getItemMessage = () => {
    const { item } = this.props
    const messages: string[] = []
    if (item.states?.find((state) => state === 'ITEM_NOT_IN_INBOUND')) {
      messages.push(__(T.error.item_not_in_inbound))
    }
    if (item.states?.find((state) => state === 'ITEM_NOT_IN_STOCK')) {
      messages.push(__(T.error.item_not_in_stock))
    }
    if (item.states?.find((state) => state === 'ITEM_IN_ANOTHER_PARCEL')) {
      messages.push(__(T.error.item_in_another_parcel))
    }
    if (item.states?.find((state) => state === 'ITEM_IN_ANOTHER_PRODUCTION_ORDER')) {
      messages.push(__(T.error.item_in_another_production_order))
    }
    if (messages.length === 0) return undefined

    return messages.join()
  }

  onDeletePress = () => {
    const { onDeleteCallback, item } = this.props
    if (onDeleteCallback) return () => onDeleteCallback(item)
    return undefined
  }
  getItemStatus = (status: string) => {
    let component
    switch (status) {
      case 'CQ2':
        component = <TagStatus style={{ backgroundColor: '#1BBA60', color: 'white' }}>COLLAUDATO</TagStatus>
        break
      case 'CQ':
        component = <TagStatus style={{ backgroundColor: '#1BBA60', color: 'white' }}>BATTEZZATO</TagStatus>
        break
      case 'SC':
        component = <TagStatus style={{ backgroundColor: '#FF9432', color: 'white' }}>SCARTATO</TagStatus>
        break
      case 'IN':
        component = <TagStatus style={{ backgroundColor: '#EF4141)', color: 'white' }}>CANCELLATO</TagStatus>
        break
      case 'SCNR':
        component = (
          <TagStatus style={{ backgroundColor: '#480808', color: 'white' }}>SCARTATO IRREVERSIBILMENTE</TagStatus>
        )
        break
      case 'PC':
        component = <TagStatus style={{ backgroundColor: '#454545', color: 'white' }}>{status}</TagStatus>
        break
      case 'MP':
        component = <TagStatus style={{ backgroundColor: '#f9dd3f', color: 'white' }}>{status}</TagStatus>
        break
      case 'LA':
        component = <TagStatus style={{ backgroundColor: '#18a0c2', color: 'white' }}>{status}</TagStatus>
        break
      default:
        component = <TagStatus style={{ backgroundColor: '#42474a', color: 'white' }}>UNKNOWN</TagStatus>
        break
    }
    return component
  }
  render() {
    const { item, id, onDeleteCallback } = this.props
    const { visibleItemModal } = this.state
    if (!item) return <></>
    const { product } = item

    let tagType
    if (!item.product) tagType = __(T.misc.unknown_product)
    if (!item.product && item.upc) tagType = __(T.misc.unknown_tag)
    if (!item.upc && !item.product) tagType = __(T.misc.invalid_tag)

    const upc = item.upc ?? item.product?.code

    let status: 'error' | 'warning' | undefined = item.__processedStates?.find((state) => state === 'WARNING')
      ? 'warning'
      : undefined
    status = item.__processedStates?.find((state) => state === 'ERROR') ? 'error' : undefined

    const message = this.getItemMessage()
    return (
      <Row row id={id} onClick={onDeleteCallback ? undefined : this.onRowPress()}>
        <Box center p={10}>
          <ProductImage src={Products.getImageUrl(product?.code)} alt="item" />
        </Box>

        <Separator />

        <Box flex p={20} pr={0}>
          <Box style={{ maxWidth: 'fit-content', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
            <ProductCode>{upc ?? __(T.misc.unknown).toUpperCase()}</ProductCode>
            <ItemEpc>{item.product?.description ?? item.epc}</ItemEpc>
            <RoundedLabel maxWidth={230} label={'Serie'} value={item.productionOrderRow?.order?.code} />
            {/* {item.itemIdentifiers.map((idf) => (
              <ItemEpc>
                {idf.identifierType}: {idf.code}
              </ItemEpc>
            ))} */}
          </Box>
          <Spacer />
          <Box row style={{ justifyContent: 'flex-start' }}>
            <RoundedLabel maxWidth={230} label={__(T.misc.style)} value={product?.style?.valueDescription} />
            <RoundedLabel maxWidth={130} label={__(T.misc.size)} value={product?.size?.value} />
            <RoundedLabel maxWidth={150} label={__(T.misc.color)} value={product?.color?.value} />
            <RoundedLabel value={tagType?.toUpperCase()} />
          </Box>
          {!!message && (
            <Box row style={{ justifyContent: 'flex-start' }}>
              <Message status={status}>{message}</Message>
            </Box>
          )}
        </Box>
        {onDeleteCallback && (
          <Box p={30} center onClick={this.onDeletePress()}>
            <Icons.Delete />
          </Box>
        )}
        <TagStatus flex>{this.getItemStatus(item.status ?? '')}</TagStatus>
        {this.onRowPress() && !onDeleteCallback && (
          <Box p={30} center>
            <Icons.Info />
          </Box>
        )}
        {visibleItemModal && (
          <CustomItemInfoModal visible={visibleItemModal} item={item} onClose={this.closeItemInfoModal} />
        )}
      </Row>
    )
  }
}

const TagStatus = styled((props) => <Box {...props} />)`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  margin: auto;
`

const Row = styled(Box)`
  margin-bottom: 20px;
  border: 2px solid #edecec;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`

const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: #edecec;
`

const ProductImage = styled(Image)`
  width: 100px;
  height: 100px;
  padding: 10px;
`

const ProductCode = styled.div`
  font-weight: 900;
  font-size: 28px;
`

const ItemEpc = styled.div`
  font-weight: 400;
  font-size: 20px;
`

const Message = styled.div<{ status?: 'error' | 'warning' }>`
  font-weight: 400;
  font-size: 20px;
  color: ${({ status }) => {
    switch (status) {
      case 'warning':
        return '#FBBF66'
      case 'error':
        return '#FD7575'
      default:
        return '#454545'
    }
  }};
`
