import { OperationConfig } from 'api/types'

export const RegisteredRoutes = {
  '/': { component: require('./Dashboard').default },
  '/tag-info': { component: require('./TagInfo/TagInfo').default },
  '/tag-info-quality-control': { component: require('./TagInfoQualityControl/TagInfoQualityControl').default },
  '/quality-control/:configCode': { component: require('./QualityControl/QualityControl').default },
  '/quality-control/worker/:configCode': { component: require('./QualityControl/QualityControlWorker').default },
  '/quality-control/inspector/:configCode': { component: require('./QualityControl/QualityControlInspector').default },
  '/quality-control-info-serie': { component: require('./QualityControl/QualityControlInfoSerie').default },
  '/quality-control-info-lotto': { component: require('./QualityControl/QualityControlInfoLotto').default },
  '/encoding/:configCode': { component: require('./CustomEncoding/CustomEncoding').default },
  '/encoding/:configCode/verify': { component: require('./Encoding/Verify').default },
  '/inbound': { component: require('./Inbound/Inbound').default },
  '/inbound/:configCode': { component: require('./Inbound/Inbound').default },
  '/inbound/:configCode/create': { component: require('./Inbound/InboundCreate').default },
  '/inbound/:configCode/reading/:parcelCode': { component: require('./Inbound/InboundReading').default },
  '/inbound/:configCode/:shippingCode/parcels': { component: require('./Inbound/InboundShipmentDetail').default },
  '/outbound': { component: require('./Outbound/Outbound').default },
  '/outbound/create': { component: require('./Outbound/OutboundCreate').default },
  '/outbound/:configCode/create': { component: require('./Outbound/OutboundCreate').default },
  '/outbound/:configCode': { component: require('./Outbound/Outbound').default },
  '/outbound/:configCode/reading': { component: require('./Outbound/OutboundReading').default },
  '/outbound/:configCode/reading/:parcelCode': { component: require('./Outbound/OutboundReading').default },
  '/outbound/:configCode/detail/:parcelCode': { component: require('./Outbound/OutboundParcelDetail').default },
  '/outbound/:configCode/:shippingCode/parcels': { component: require('./Outbound/OutboundShipmentDetail').default },
  '/transfer-to-zone': { component: require('./TransferToZone/TransferToZone').default },
  '/transfer-to-zone/reading': { component: require('./TransferToZone/TransferToZoneReading').default },
  '/test': { component: require('./Test').default },
}

export const PublicRoutes = {
  '/login': { component: require('./Login').default },
}

export type Routes = keyof typeof RegisteredRoutes | keyof typeof PublicRoutes

export interface PageParams {
  operation?: OperationConfig
}
