import { Button, Icons } from 'components'
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { closeModal, getDatetime } from 'shared/utils'
import { CQLot } from 'api/types'
import { navigate } from 'shared/router'
import Box from './Box'

interface Props {
  lotCode?: string
  date?: number
  serie?: string
  lotData: CQLot
  modalId: string
}

interface State {
  showInfo: boolean
}

export default class LotRow extends Component<Props, State> {
  state: State = {
    showInfo: false,
  }
  render() {
    const { showInfo } = this.state
    const { lotCode, date, serie, lotData, modalId } = this.props
    const dateFormatted = date ? getDatetime(date) : ''
    return (
      <>
        <LotsContainer>
          <ButtonContainer
            onClick={() => {
              this.setState({ showInfo: !showInfo })
            }}
          >
            <FieldText>{lotCode}</FieldText>
            <DateText>{dateFormatted}</DateText>
            <Icons.ArrowDownBold width={25} height={25} />
          </ButtonContainer>
          <InfoContainer visible={showInfo}>
            <Row>
              <InfoTextContainer>
                <InfoTextLabel>
                  Data creazione:
                  <InfoTextValue> {dateFormatted}</InfoTextValue>
                </InfoTextLabel>
              </InfoTextContainer>
              <InfoTextContainer>
                <InfoTextLabel>
                  Serie:
                  <InfoTextValue> {serie}</InfoTextValue>
                </InfoTextLabel>
              </InfoTextContainer>
            </Row>
            <Row>
              <InfoTextContainer>
                <InfoTextLabel>
                  N. pezzi battezzati:
                  <InfoTextValue> {lotData.tested}</InfoTextValue>
                </InfoTextLabel>
              </InfoTextContainer>
              <Button
                title="INFO"
                size="small"
                onClick={() => {
                  closeModal(modalId)
                  navigate('/quality-control-info-lotto', {}, { state: { lotCode: lotData.code } })
                }}
              />
            </Row>
          </InfoContainer>
        </LotsContainer>
      </>
    )
  }
}

const LotsContainer = styled.div<{}>``

const InfoContainer = styled(Box)<{ visible?: boolean }>`
  ${({ visible }) => (!visible ? `display:none;` : `display:block;`)}
  padding:20px
`

const ButtonContainer = styled.button<{}>`
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  min-height: 74px;
  background-color: #d1d1d1;
  justify-content: space-between;
  border-bottom: 1px solid #969696;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const InfoTextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 10px;
`
const InfoTextLabel = styled.text`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: #333333;
`
const InfoTextValue = styled.text`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: grey;
`

const DateText = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  color: grey;
  align-items: center;
`
const FieldText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  width: auto;
  align-items: center;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
