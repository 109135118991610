import api, { responseErrorCheck } from './api'
import { ShipmentParcelPreview } from './types'

export interface BatchDecodeRequest {
  configurationId: string
  identifiers: string[]
  parcelDto?: ShipmentParcelPreview
}

export default class Shipments {
  static endpoint = '/shipping'

  static batchDecode<T>(data: BatchDecodeRequest) {
    return api.post<T>(`${this.endpoint}/batchDecode`, data).then((res) => res.data)
  }

  static batchValidate<T>(data: BatchDecodeRequest) {
    return api.post<T>(`${this.endpoint}/batchValidate`, data).then(responseErrorCheck)
  }
}
