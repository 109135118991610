import React, { Component } from 'react'
import { ShipmentParcelPreview } from 'api/types'
import { Box, List, ParcelStatus } from 'components'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import { getDatetime } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'

interface Props {
  shipmentParcel: ShipmentParcelPreview
  onRowClick: (shipmentParcel: ShipmentParcelPreview) => void
  type: 'inbound' | 'outbound'
}
export default class ShipmentParcelRow extends Component<Props> {
  render() {
    const { onRowClick, shipmentParcel, type } = this.props
    const counter = ShipmentProvider.getTotalCounters(shipmentParcel)

    return (
      <RowItem onClick={() => onRowClick(shipmentParcel)}>
        <List.Cell label={__(T.misc.parcel)} value={shipmentParcel.header?.parcelCode} />
        <Box style={{ width: 160 }}>
          <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
          <ParcelStatus type={type} width={150} status={shipmentParcel.header.parcelState} />
        </Box>
        <List.Cell style={{ width: 240 }} label={__(T.misc.shipment)} value={shipmentParcel.header?.shippingCode} />
        {type === 'outbound' && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.destination)}
            value={
              shipmentParcel.header?.destinationPlace?.description || shipmentParcel.header?.destinationPlace?.code
            }
          />
        )}
        {type === 'inbound' && (
          <List.Cell
            style={{ width: 240 }}
            label={__(T.misc.origin)}
            value={shipmentParcel.header?.originPlace?.description || shipmentParcel.header?.originPlace?.code}
          />
        )}
        <List.Cell
          style={{ width: 240 }}
          label={__(T.misc.creation_date)}
          value={getDatetime(shipmentParcel.header?.parcelCreationDate)}
        />
        <Box width={100}>
          <SmallCounter unexpected={counter.unexpected} detected={counter.detected} expected={counter.expected}>
            {`${counter.detected}${counter.expected > 0 ? `/${counter.expected}` : ''}`}
          </SmallCounter>
        </Box>
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
