import React, { Component } from 'react'
import { Box, Modal } from 'components'
import styled from '@emotion/styled'

interface Props {
  onClose: () => void
  visible: boolean
  image?: string
}

export default class ImageDetailModal extends Component<Props> {
  render() {
    const { onClose, visible, image } = this.props
    return (
      <Modal
        size="6xl"
        onClose={onClose}
        title="Dettaglio Immagine"
        containerStyle={{ background: 'red' }}
        visible={visible}
      >
        <CenteredContainer>
          <ImageBox src={image} />
        </CenteredContainer>
      </Modal>
    )
  }
}

const ImageBox = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`
export const CenteredContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`
