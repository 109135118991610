import React, { Component } from 'react'
import { ItemIdentifier } from 'api/types'
import styled from '@emotion/styled'
import { Box, Icons } from 'components'

interface Props {
  identifier: ItemIdentifier
  last?: boolean
}
export default class IdentifierRow extends Component<Props> {
  render() {
    const { identifier, last } = this.props
    return (
      <Box vcenter row>
        {identifier.identifierType === 'UHFTag' && (
          <Icons.Rfid width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        {identifier.identifierType === 'NFCTag' && (
          <Icons.Nfc width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        {identifier.identifierType !== 'UHFTag' && identifier.identifierType !== 'NFCTag' && (
          <Icons.Serial width={20} height={20} style={{ marginTop: -7, marginRight: 17 }} />
        )}
        <Box flex>
          <Code height={40} vcenter>
            {identifier.code}
          </Code>
          <Separator last={last} />
        </Box>
      </Box>
    )
  }
}

const Separator = styled(Box)<{ last?: boolean }>`
  height: 1px;
  background-color: ${({ last }) => (last ? 'transparent' : '#e0e0e0')};
`

const Code = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 13;
  line-height: 15px;
`
