import React from 'react'
import styled from '@emotion/styled'

export default function Chip({
  children,
  style,
  id,
}: {
  children?: React.ReactNode
  style?: React.CSSProperties
  id?: any
}) {
  return (
    <Container id={id} key={id} style={style}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  padding: 8px 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex: 0;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  min-width: fit-content;
`
