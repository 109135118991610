import React, { Component } from 'react'
import { ShipmentParcel } from 'api/types'
import { __, T } from 'translations/i18n'
import { OutboundConfig, InboundConfig } from 'shared/RemoteConfig'
import { navigate } from 'shared/router'
import Box from './Box'
import Button from './Button'
import ParcelStatus from './ParcelStatus'
import { Spacer } from './Various'

interface Props {
  parcel: ShipmentParcel
  operation: InboundConfig | OutboundConfig
}

export default class ParcelSectionHeader extends Component<Props> {
  openReadParcel = () => {
    const { operation, parcel } = this.props
    const state = { shippingCode: parcel.header.shippingCode }
    navigate(
      `/${operation.operationType}/:configCode/reading/:parcelCode` as any,
      {
        configCode: operation?.code,
        parcelCode: parcel.header.parcelCode,
        fromShipment: true,
      },
      { state }
    )
  }

  openDetailParcel = () => {
    const { operation, parcel } = this.props
    const state = { shippingCode: parcel.header.shippingCode }
    navigate(
      '/outbound/:configCode/detail/:parcelCode',
      {
        configCode: operation?.code,
        parcelCode: parcel.header.parcelCode,
      },
      { state }
    )
  }

  renderInboundButton = () => {
    const { parcel } = this.props
    if (parcel.header?.parcelState === 'IN_INBOUND' || parcel.header?.parcelState === 'IN_TRANSIT') {
      return (
        <Button
          style={{ paddingRight: 20, paddingLeft: 20 }}
          size="medium"
          title={__(T.misc.receive)}
          onClick={this.openReadParcel}
        />
      )
    }
    return <></>
  }

  renderOutboundButton = () => {
    const { parcel } = this.props
    if (parcel.header?.parcelState === 'DRAFT') {
      return (
        <Button
          style={{ paddingRight: 20, paddingLeft: 20 }}
          size="medium"
          title={__(T.misc.open)}
          onClick={this.openReadParcel}
        />
      )
    }
    return (
      <Button
        variant="secondary"
        style={{ paddingRight: 20, paddingLeft: 20 }}
        size="medium"
        title={__(T.misc.detail)}
        onClick={this.openDetailParcel}
      />
    )
  }

  render() {
    const { parcel, operation } = this.props
    return (
      <Box vcenter row flex pr={20}>
        {parcel.header?.parcelCode ?? 'No parcel code provided'}
        <Spacer />
        <ParcelStatus type={operation.operationType} status={parcel.header?.parcelState ?? '---'} />
        <Box flex />
        {'inboundMode' in operation && this.renderInboundButton()}
        {'outboundMode' in operation && this.renderOutboundButton()}
      </Box>
    )
  }
}
