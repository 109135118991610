import React, { Component } from 'react'
import { Box, Image, Modal } from 'components'
import { TmrItem } from 'api/types'
import Products from 'api/Products'
import styled from '@emotion/styled'

interface Props {
  isOpen?: boolean
  onClose: () => void
  style?: any
  label?: string
  items: TmrItem[]
}

const ImageContainer = styled.div`
  // border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 2px;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
`

export default class ItemsModal extends Component<Props> {
  render() {
    const { isOpen, onClose, label, items } = this.props
    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="full" title={label}>
        <Box>
          {items.map((item) => (
            <Box row pb={10} mb={10} style={{ borderBottom: '1px solid #eeee' }}>
              <ImageContainer>
                <Image
                  style={{ objectFit: 'contain', width: '100%' }}
                  src={Products.getImageUrl(item.product?.code)}
                  alt="item"
                />
              </ImageContainer>
              <Box>
                <Box>EPC: {item.epc}</Box>
                <Box>{item.product?.code && `UPC: ${item.product?.code}`}</Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Modal>
    )
  }
}
