import React from 'react'
import { Form } from 'components'
import { __, T } from 'translations/i18n'
import { closeModal, openModal } from 'shared/utils'
import { format } from 'date-fns'

export function CustomInboundAsnConfirmModal(data: any, resolve: any, reject: any) {
  const modalId = 'inbound-before-confirm'
  openModal({
    id: modalId,
    title: '',
    body: (
      <Form
        autoComplete="off"
        submitText={__(T.misc.confirm)}
        schema={[
          {
            name: 'ddtNumber',
            label: 'Numero DDT',
            type: 'number',
            required: true,
          },
          {
            name: 'ddtDate',
            label: 'Data DDT',
            type: 'date',
            required: true,
          },
        ]}
        onSubmit={async (d: any) => {
          closeModal(modalId)
          data.attributes = {
            ddtNumber: d.ddtNumber,
            ddtDate: format(new Date(d.ddtDate), 'yyyyMMdd'),
          }
          resolve(true)
          // reject('Unable to confirm')
        }}
      />
    ),
    onClose: () => reject(new Error('Completa il form per confermare')),
  })
}

export function CustomInboundParcelConfirmModal(data: any, resolve: any, reject: any) {
  const modalId = 'inbound-before-confirm'

  openModal({
    id: modalId,
    title: '',
    body: (
      <Form
        autoComplete="off"
        submitText={__(T.misc.confirm)}
        schema={[
          {
            name: 'ddtNumber',
            label: 'Numero DDT',
            type: 'number',
            required: true,
          },
          {
            name: 'ddtDate',
            label: 'Data DDT',
            type: 'date',
            required: true,
          },
        ]}
        onSubmit={async (d: any) => {
          closeModal(modalId)
          data.attributes = {
            ddtNumber: d.ddtNumber,
            ddtDate: format(new Date(d.ddtDate), 'yyyyMMdd'),
          }
          resolve(true)
          // reject('Unable to confirm')
        }}
      />
    ),
    onClose: () => reject(new Error('Completa il form per confermare')),
  })
}
