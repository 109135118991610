import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import { MotionStyle } from 'framer-motion'

const Title = styled.div<{ center; nomargin }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;
  ${({ center }) => center && `align-self: center;`}
  opacity: 0.4;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0px' : '20px')};
`

interface Props {
  title?: string
  nomargin?: boolean
  center?: boolean
  style?: MotionStyle
}
export default class Card extends Component<Props> {
  static Title = Title
  render() {
    const { children, title, style, center, nomargin } = this.props
    return (
      <CardContainer title={title} style={style}>
        {title && (
          <Title nomargin={nomargin} center={center}>
            {title}
          </Title>
        )}
        {children}
      </CardContainer>
    )
  }
}

const CardContainer = styled(Box)<{ title?: string }>`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  ${({ title }) => title && `padding: 20px 20px;`}
`
