import styled from '@emotion/styled'
import config from 'config/config'
import React, { Component } from 'react'
import Box from './Box'

export interface Props {
  file: any
  remote?: boolean
}
export default class File extends Component<Props> {
  // componentDidMount() {
  //   this.getSourceImageByUpcJpeg(this.props.file.id)
  // }

  // async getSourceImageByUpcJpeg(id) {
  //   const res = await api.get(`attachments/${id}`)
  //   console.log(res)
  //   return ''
  // }

  isAnImage = (imageName: String) => {
    return imageName.includes('jpg') || imageName.includes('png') || imageName.includes('jpeg')
  }

  render() {
    const { file, remote } = this.props
    const isImage = !remote ? file.type.includes('image') : this.isAnImage(file.originalName)
    let fileName = remote ? file.originalName : file.name.substring(0, file.name.lastIndexOf('.'))
    const fileType = remote
      ? file.originalName.substring(file.originalName.lastIndexOf('.') + 1, file.originalName.length).toUpperCase()
      : file.type.split('/')[1].toUpperCase()

    const url = remote ? `${config.endpoint}attachments/${file.id}/download` : ''

    if (fileName.length > 20) fileName = `${fileName.substring(0, 20)}...`
    return (
      <div
        role="presentation"
        onClick={() => {
          if (remote) window.open(url, '_blank')
          else window.open(file.preview, '_blank')
        }}
      >
        <AttachmentsBox>
          <Box style={{ flex: 1, justifyContent: 'center', height: '100%' }}>
            {((file.preview && isImage) || (isImage && remote)) && (
              <img src={remote ? url : file.preview} alt={fileName} style={{ height: '100%', objectFit: 'cover' }} />
            )}
            {!isImage && <DocumentText>{fileType}</DocumentText>}
          </Box>
        </AttachmentsBox>
        <Text>
          {fileName} {isImage && !remote ? `.${fileType}` : null}
        </Text>
      </div>
    )
  }
}

const AttachmentsBox = styled(Box)`
  margin-top: 15px;
  width: 200px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 200px;
`
const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-top: 6px;
  align-self: center;
  text-align: center;
`

const DocumentText = styled.p`
  font-weight: 900;
  font-size: 20px;
  line-height: 21px;
  color: '#fff';
  text-align: center;
`
