import styled from '@emotion/styled'
import EncodingsQC from 'api/EncodingsQC'
import { CQLot, CQLotCounter, CQLotInfo } from 'api/types'
import { ApisauceConfig } from 'apisauce'
import { Box, Card, InfoCard, Spacer, Icons, Table, Button, Image } from 'components'
import React, { Component } from 'react'
import RemoteConfig from 'shared/RemoteConfig'
import { T, __ } from 'translations/i18n'

interface Props {
  lot: CQLotInfo
}

export default class LottoInfoBox extends Component<Props> {
  operation = RemoteConfig.getOperationConfig<any>('quality-control-worker')

  downloadPDF = async () => {
    const { lot } = this.props
    await EncodingsQC.getCQLotPdfInfo(lot.code, this.operation)
  }

  render() {
    const { lot } = this.props
    const date = new Date(lot.creationDate).toLocaleDateString()
    const time = new Date(lot.creationDate).toLocaleTimeString()
    const dateTime = `${date} ${time}`
    return (
      <Box style={{ padding: 20 }}>
        <CounterContainer row>
          <Box style={{ width: '50%' }}>
            <Image //temporanea, da sostituire con vero barcode
              style={{ height: 100, paddingTop: 10 }}
              src={`data:image/jpeg;base64,${lot.barcode}`}
              alt="Barcode"
            />
          </Box>
          <Box style={{ width: '50%', padding: 5 }}>
            <Card title="Lot" nomargin>
              <Counter>{lot.code}</Counter>
            </Card>
          </Box>
        </CounterContainer>
        <CounterContainer row>
          <Box style={{ width: '50%', padding: 5 }}>
            <Card title="N. pezzi battezzati" nomargin>
              <Counter>{lot.testedTotalCount}</Counter>
            </Card>
          </Box>
          <Box style={{ width: '50%', padding: 5 }}>
            <Card title="Data e ora" nomargin>
              <Counter>{dateTime}</Counter>
            </Card>
          </Box>
        </CounterContainer>
        {lot.testedPerFitSizeQuantities.length > 0 && (
          <Table
            structure={[
              { label: 'Taglia', value: 'fit' },
              { label: 'Calzata', value: 'size' },
              { label: 'Quantità', value: 'quantity' },
            ]}
            data={lot.testedPerFitSizeQuantities}
          />
        )}
        <Spacer />
        <Box flex center>
          <Button title="Scarica" style={{ width: '50%' }} onClick={() => this.downloadPDF()} />
        </Box>
      </Box>
    )
  }
}

const CounterContainer = styled(Box)`
  justify-content: space-between;
`

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 25px;
  font-weight: bold;
`
