import { ApisauceConfig, ApisauceInstance, create } from 'apisauce'

let api: ApisauceInstance

if (api! === undefined) {
  api = create({
    baseURL: 'http://127.0.0.1:8080', //'http://10.20.33.50:8080', //
    timeout: 60000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    //paramsSerializer,
  })
}

export type ValidationBodyRequest = {
  operatoreCQ?: string
  idPostazione?: string
  uId?: string
  lavorante?: string
  serieProduttiva?: string
  collezione?: string
  sku?: string
  taglia?: string
  calzata?: string
  cliente?: string
  zona?: string
  idcau?: string
}

export default class QualityControl {
  static endpoint = '/RfidHttpAgent/rfidService'
  static readTag(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/readTag`, params, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static stopReader() {
    return (
      api
        .get<any>(`${this.endpoint}/stopReadTag`)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static writeTagCQ(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/writeTagCQLocal`, params, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static writeTagSC(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/writeTagSCLocal`, params, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static cancelTag(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/cancelTagLocal`, params, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static testConnection(requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/testConnection`, undefined, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }

  static stopReadTag(requestConfig?: ApisauceConfig) {
    return (
      api
        .get<any>(`${this.endpoint}/stopReadTag`, undefined, requestConfig)
        //.then(responseErrorCheck)
        .then((res) => res)
    )
  }
}
