import React, { Component } from 'react'
import { Form, Page, Box, Icons } from 'components'
import { getLocationState, getMatchParams, navigate } from 'shared/router'
import { ShippingParcelCreateRequest, TmrPlace } from 'api/types'
import { showToast, uuid } from 'shared/utils'
import Places from 'api/Places'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import OutboundShipments from 'api/OutboundShipments'

interface State {
  places?: TmrPlace[]
  initialValues?: any
  fromShipment: boolean
  loading: boolean
}
export default class OutboundCreate extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    places: [],
    fromShipment: getLocationState(this.props)?.fromShipment,
    initialValues: {
      destination: getLocationState(this.props)?.destination,
      shipmentCode: getLocationState(this.props)?.shipmentCode,
    },
    loading: true,
  }

  componentDidMount() {
    this.fetchPlaces()
  }

  fetchPlaces = async (input?: string) => {
    const { initialValues } = this.state
    try {
      const places = (await Places.search<TmrPlace>({ limit: 500 })) ?? []
      if (places.length === 1 && !initialValues.destination) {
        initialValues.destination = places[0]
      }
      this.setState({ places, initialValues, loading: false })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = async (data) => {
    const { fromShipment } = this.state
    if (!data.destination) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }

    let state = {
      shipmentCode: data.shipmentCode ?? uuid(),
      parcelCode: data.parcelCode ?? uuid(),
      destination: data.destination,
      fromShipment: fromShipment,
    }
    try {
      if (this.operation?.id && (!data.shipmentCode || !data.parcelCode)) {
        const request: ShippingParcelCreateRequest = {
          configurationId: this.operation.id,
          destinationPlaceCode: data.destination.code,
        }
        if (data.shipmentCode) request.shippingCode = data.shipmentCode
        if (data.parcelCode) request.parcelCode = data.parcelCode
        const response = await OutboundShipments.createParcel(request)
        if (!response || !response?.header?.parcelCode) throw new Error('No Parcel created') // Never shown to user

        state = {
          shipmentCode: response?.header?.shippingCode,
          parcelCode: response?.header?.parcelCode,
          destination: data.destination,
          fromShipment,
        }
        navigate('/outbound/:configCode/reading', { configCode: this.operation?.code }, { state })
      }
    } catch (error) {
      // if catch an error create shipment and parcel codes with uuid and navigate without errors
    } finally {
      navigate('/outbound/:configCode/reading', { configCode: this.operation?.code }, { state })
    }
  }

  onBackPress = () => {
    if (this.state.fromShipment) {
      navigate(`/outbound/:configCode/:shippingCode/parcels`, {
        configCode: this.operation?.code,
        shippingCode: this.state.initialValues?.shipmentCode,
      })
      return
    }
    if (this.operation.hasChecklist === 'yes' || this.operation.outboundMode === 'shipment') {
      navigate(`/outbound/:configCode`, { configCode: this.operation?.code })
    } else {
      navigate(`/`)
    }
  }

  render() {
    const { places, initialValues, loading } = this.state

    return (
      <Page title={this.operation?.description ?? 'Outbound'} onBackPress={this.onBackPress}>
        <Page.Content bgGrey>
          <Box flex center loading={loading}>
            <Form
              schema={[
                {
                  label: __(T.misc.shipment_code_auto),
                  name: 'shipmentCode',
                  image: <Icons.Barcode />,
                  focus: !initialValues.shipmentCode,
                  hide: this.state.fromShipment,
                },
                {
                  label: __(T.misc.parcel_code_auto),
                  name: 'parcelCode',
                  image: <Icons.Barcode />,
                  focus: initialValues.shipmentCode,
                },
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: true,
                  type: 'select',
                  options: places,
                  config: { value: 'id', label: 'description', secondaryLabel: 'code' },
                  hide: this.state.fromShipment,
                },
              ]}
              initialValues={initialValues}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
