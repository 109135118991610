import React from 'react'
import { Box, GroupedProductRow, KeyValueRow } from 'components'
import styled from '@emotion/styled'
import { TmrItem } from 'api/types'
import { T, __ } from 'translations/i18n'

export default function ItemBox({
  title = __(T.fields.identified_item),
  item,
  customFields,
}: {
  title?: string
  item?: TmrItem
  customFields?: { label: string; value: string; style?: React.CSSProperties }[]
}) {
  const location =
    item?.zone?.place?.description || item?.zone?.description
      ? `${item?.zone?.place?.description ? `${item?.zone?.place?.description} - ` : ''}${
          item?.zone?.description ?? ''
        }`
      : undefined

  return (
    <CardContainer>
      <Title>{title}</Title>
      <Box mt={10} row>
        {!!item?.product && <GroupedProductRow product={item?.product} hideCounter noShadow flex />}
      </Box>
      <Box row>
        <InfoContainer>
          {!!location && (
            <KeyValueRow label={__(T.fields.current_location)} value={location} style={{ marginBottom: 10 }} />
          )}
          {item?.itemIdentifiers &&
            item.itemIdentifiers.map((id) => (
              <KeyValueRow label={id.type.replace('Dto', '')} value={id.code} style={{ marginBottom: 10 }} />
            ))}
          {customFields &&
            customFields.map((field) => (
              <KeyValueRow label={field.label} value={field.value} style={field.style ?? { marginBottom: 10 }} />
            ))}
        </InfoContainer>
      </Box>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  padding: 30px;
  padding-bottom: 20px;
  flex-direction: column;
  position: relative;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  opacity: 0.4;
`

const InfoContainer = styled.div`
  flex: 2;
`
