import React, { Component } from 'react'
import { Box, Modal, Button, Spacer, File, Icons } from 'components'
import styled from '@emotion/styled'
import { Textarea } from '@chakra-ui/react'
import { TestingSheet } from 'api/types'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import AddImageComponent from '../AddImageComponent'

interface Props {
  onClose: () => void
  onSubmitCallback: (testingSheet: Partial<TestingSheet>, updateSheet: boolean) => void
  visible: boolean
  testingSheet?: TestingSheet
}

interface State {
  note?: string
  files: any[]
  isInModify: boolean
  loading: boolean
  currentFilesSize: number
}

export default class SeriesNotesModal extends Component<Props, State> {
  state: State = {
    note: this.props.testingSheet?.inspectionNote ?? '',
    files: this.props.testingSheet?.attachments ?? [],
    isInModify: !!this.props.testingSheet,
    loading: false,
    currentFilesSize: 0,
  }

  maxFilesSize = 5000000 //5MB

  getFiles = (files) => {
    const { currentFilesSize } = this.state
    let filesSize = 0

    //iterate all the files and check if the sum of all sizes is < of maxFilesSize
    files.forEach((file) => {
      filesSize += file.size
    })
    if (currentFilesSize + filesSize <= this.maxFilesSize)
      this.setState({ files: this.state.files.concat(files), currentFilesSize: currentFilesSize + filesSize })
    //Add new files
    //ignoring new Files and show error
    else
      showToast({
        title: __(T.error.error),
        description: __(T.error.larger_file_error),
        status: 'error',
      })
  }

  subMit = async () => {
    const { isInModify, note, files } = this.state
    const { onSubmitCallback } = this.props
    this.setState({ loading: true })
    await onSubmitCallback(
      {
        notes: note ?? '',
        attachmentFiles: files,
      },
      isInModify
    )
    this.setState({ loading: false, isInModify: false, note: undefined, files: [] })
  }

  render() {
    const { onClose, visible } = this.props
    const { note, loading, currentFilesSize } = this.state
    return (
      <Modal size={loading ? 'xs' : '5xl'} onClose={onClose} containerStyle={{ background: 'red' }} visible={visible}>
        {loading && <Icons.Loader />}
        {!loading && (
          <Box>
            <Box vcenter row mb={30}>
              <Modal.Title>Note Serie</Modal.Title>
            </Box>
            <Box row>
              <CustomTextArea
                onChange={(event) => this.setState({ note: event.target.value })}
                value={note}
                placeholder="note"
              />
            </Box>
            <Spacer />
            <Title> Allega Immagini </Title>
            <Box row>
              <Spacer />
              <Grid>
                <AddImageComponent getFiles={this.getFiles} />
                {this.state.files?.map((file) => (
                  <>
                    <File file={file} remote={!file.preview} />
                  </>
                ))}
              </Grid>
            </Box>
            <Spacer />
            <Spacer />
            <RightBox row>
              <Button style={{ width: 187 }} onClick={onClose} variant="ignore">
                Annulla
              </Button>
              <Spacer />
              <Button style={{ width: 187 }} variant="primary" onClick={this.subMit}>
                Salva
              </Button>
            </RightBox>
          </Box>
        )}
      </Modal>
    )
  }
}

const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 3px;
`

const CustomTextArea = styled(Textarea)`
  font-size: 24px;
`
const RightBox = styled(Box)`
  justify-content: flex-end;
`

const Grid = styled.div`
  display: grid;
  padding: 1px;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  grid-column-gap: 90px;
`
