import api, { responseErrorCheck } from './api'
import { SerieCqType } from './types'

export default class Serie {
  static endpoint = '/custom/productionOrders'

  static getSerieInfo(code: string): Promise<SerieCqType> {
    return api.get<SerieCqType>(`${this.endpoint}/info?code=${code}`).then(responseErrorCheck)
  }
}
