import { TmrPlace, TmrZone } from 'api/types'
import { Box, Form, Page } from 'components'
import React, { Component } from 'react'
import { navigate } from 'shared/router'
import { T, __ } from 'translations/i18n'
import { showToast } from 'shared/utils'
import Zones from 'api/Zones'
import AppStore from 'AppStore'

interface State {
  zones?: TmrZone[]
}

export default class Inbound extends Component<{}, State> {
  state: State = {
    zones: [],
  }

  componentDidMount() {
    this.fetchzones()
  }

  fetchzones = async () => {
    try {
      this.setState({ zones: (await Zones.search<TmrPlace>({ 'place.id': AppStore.loggedUser?.place.id })) ?? [] })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  submit = (data) => {
    if (!data.destination) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.destination_field_required),
        status: 'error',
      })
      return
    }
    navigate('/transfer-to-zone/reading', {}, { state: data })
  }

  render() {
    const { zones } = this.state
    return (
      <Page title={__(T.titles.transfer_to_zone)} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  placeholder: __(T.misc.destination),
                  name: 'destination',
                  required: true,
                  type: 'select',
                  options: zones,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.submit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
