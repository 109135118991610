import React, { Component } from 'react'
import { getMatchParams } from 'shared/router'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import InboundByShipment from './InboundByShipment'
import InboundByParcel from './InboundByParcel'

export default class Inbound extends Component {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  render() {
    if (this.operation.inboundMode === 'parcelByParcel') return <InboundByParcel operation={this.operation} />
    if (this.operation.inboundMode === 'shipment') return <InboundByShipment operation={this.operation} />

    throw new Error(`${this.operation.inboundMode} operation mode not defined`)
  }
}
