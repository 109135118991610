import React, { Component } from 'react'
import { Button, List, Input, Page, Select, Spacer, Box, Icons, ShipmentRow } from 'components'
import { navigate } from 'shared/router'
import { ShipmentPreview } from 'api/types'
import OutboundShipments from 'api/OutboundShipments'
import { showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import { OutboundConfig } from 'shared/RemoteConfig'

interface State {
  data: ShipmentPreview[]
  query: string
  destinationFilter?: ShipmentPreview
  destinations?: ShipmentPreview[]
  loading: boolean
}

interface Props {
  operation: OutboundConfig
}

export default class OutboundByShipment extends Component<Props, State> {
  operation = this.props.operation

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    OutboundShipments.shipmentCounters({
      parcelStates: ['DRAFT', 'IN_TRANSIT', 'SEND_AWAIT', 'OUTBOUND_ERROR'],
    })
      .then((data) => {
        this.setState({
          loading: false,
          data: data ?? [],
          destinations: uniqueBy(data ?? [], 'destinationPlaceCode'),
        })
      })
      .catch((err) => {
        this.setState({ loading: false, data: [] })
        showToast({
          title: __(T.error.error),
          description: err ?? 'Generic error',
          status: 'error',
        })
      })
  }

  onRowClick = (itm: ShipmentPreview) => {
    navigate('/outbound/:configCode/:shippingCode/parcels', {
      configCode: this.operation?.code,
      shippingCode: itm.shippingCode,
    })
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  filterResults = (result: ShipmentPreview) =>
    result.shippingCode.toLowerCase().includes(this.state.query.toLowerCase()) &&
    ((this.state.destinationFilter &&
      result.destinationPlace?.code === this.state.destinationFilter?.destinationPlace?.code) ||
      !this.state.destinationFilter)

  render() {
    const { data, destinations, loading } = this.state
    const filtered = data.filter(this.filterResults)

    return (
      <Page title={this.operation?.description ?? 'Outbound'}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.placeholder.search_shipment_parcel)}
              onChange={(query) => this.setState({ query })}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              containerStyle={{ width: 250 }}
              borderColor="transparent"
              transparent
              onSelect={(itm) => this.setState({ destinationFilter: itm })}
              options={destinations ?? []}
              placeholder={__(T.misc.destination)}
              config={{
                value: 'header.destinationPlace.code',
                label: 'header.destinationPlace.description',
                secondaryLabel: 'header.destinationPlace.code',
              }}
            />
            <Box flex />
            <Button
              title={__(T.misc.new_shipment)}
              onClick={() => navigate('/outbound/:configCode/create', { configCode: this.operation?.code })}
            />
          </Page.TopBar>

          <List
            loading={loading}
            title={`${__(T.titles.shipment_to_confirm)} (${filtered.length})`}
            renderItem={(itm) => <ShipmentRow operation={this.operation} shipment={itm} onRowClick={this.onRowClick} />}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
