import React, { Component } from 'react'
import { AntennaButton, GroupedProductList, Page, TagCounter, Box, Button, ItemsStatesRow } from 'components'
import { GroupedShipmentProduct, ShipmentParcel, TmrItem, TmrPlace } from 'api/types'
import { navigate, getMatchParams, getLocationState } from 'shared/router'
import ShipmentProvider, { CheckListType } from 'ShipmentProvider'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'
import { outboundParcelBeforeConfirm, outboundParcelAfterConfirm } from 'pages/_extensions_/OutboundExtensions'
import RfidReader from 'shared/RfidReader'
import Shipments from 'api/Shipments'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface NewPack {
  shipmentCode: string
  parcelCode: string
  destination: TmrPlace
}

interface State {
  newPack?: NewPack
  parcel?: ShipmentParcel
  checkListType: CheckListType
  groupedProducts: GroupedShipmentProduct[]
  loading: boolean
}

export default class OutboundReading extends Component<{}, State> {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    checkListType: 'ITEMS',
    groupedProducts: [],
    loading: true,
  }

  checkListType!: CheckListType

  async componentDidMount() {
    const { parcelCode } = getMatchParams(this.props)
    const locationState = getLocationState(this.props)
    let parcel
    try {
      if (!parcelCode && (!locationState?.parcelCode || !locationState?.shipmentCode || !locationState?.destination))
        throw new Error('Invalid data for parcel creation')
      if (!this.operation) throw new Error('Operation configuration not found')
      if (this.operation.readingMode !== 'rfid') throw new Error(__(T.error.not_supported_reading_mode))
      if (!parcelCode) {
        parcel = ShipmentProvider.createShipmentParcel(
          locationState.parcelCode,
          locationState.shipmentCode,
          locationState.destination
        )
      }
      const {
        outboundShipmentParcel,
        checkListType,
        groupedProducts,
      } = await ShipmentProvider.fetchOutboundShipmentParcels(parcelCode, parcel)
      await this.initRfidDevice(checkListType)

      this.setState({
        loading: false,
        parcel: outboundShipmentParcel,
        checkListType,
        groupedProducts,
      })
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  async initRfidDevice(checkListType) {
    await RfidReader.initialize()

    if (checkListType === 'TAGS') {
      RfidReader.onTagReadCallback = this.handleReceivedTags
    } else {
      RfidReader.onDecodedItemCallback = this.onDecodedItemCallback
      RfidReader.setDecodeFunction(this.decodeFunction)
    }
  }

  decodeFunction = (epcs: string[]) => {
    if (!this.operation) throw new Error('Operation configuration cannot be undefined')

    return Shipments.batchValidate<any>({
      configurationId: this.operation.id,
      identifiers: epcs,
    })
  }

  onDecodedItemCallback = async (itemMap) => {
    const { parcel, groupedProducts } = this.state
    const items: TmrItem[] = Object.values<TmrItem>(itemMap)

    ShipmentProvider.processOutboundItemsStates(items, this.operation)

    ShipmentProvider.processItemForItemsChecklist(items, [parcel!], groupedProducts)
    this.forceUpdate()
  }

  handleReceivedTags = () => {}

  clear = () => {
    const { parcel } = this.state
    const groupedProducts = ShipmentProvider.clearAllReceivingReadings([parcel!])
    this.setState({ groupedProducts })
    RfidReader.clear()
  }

  navigateBack = () => {
    if (this.operation.outboundMode === 'shipment') {
      navigate('/outbound/:configCode/:shippingCode/parcels', {
        configCode: this.operation?.code,
        shippingCode: this.state.parcel?.header?.shippingCode,
      })
      return
    }
    if (this.operation.hasChecklist === 'yes') {
      navigate('/outbound/:configCode', { configCode: this.operation?.code })
    } else {
      navigate('/outbound/:configCode/create', { configCode: this.operation?.code })
    }
  }

  confirmOutbound = async () => {
    try {
      const { parcel } = this.state
      if (parcel?.detectedItems?.filter((item) => item.__processedStates?.includes('ERROR')).length)
        throw new Error(__(T.error.items_in_error_found))

      // extension point "beforeConfirm"
      await outboundParcelBeforeConfirm(parcel!, this.operation)

      await ShipmentProvider.confirmOutbound([parcel!], this.operation!.id, parcel!.header.destinationPlace.code)

      // extension point "afterConfirm"
      await outboundParcelAfterConfirm(parcel!, this.operation)

      showToast({
        title: __(T.misc.success),
        description: __(T.messages.outbound_success),
        status: 'success',
      })

      this.navigateBack()
    } catch (error) {
      error &&
        showToast({
          title: __(T.error.error),
          description: error?.message ?? 'Generic error',
          status: 'error',
        })
    }
  }

  removeItemFromReadings = (item: TmrItem) => {
    const { parcel, groupedProducts } = this.state
    ShipmentProvider.removeItemsForItemsChecklist([item], [parcel!], groupedProducts)
    const itemIdentifiers = item.itemIdentifiers.map((id) => id.code)
    RfidReader.removeTags(itemIdentifiers)
    this.forceUpdate()
  }

  render() {
    const { parcel, checkListType, groupedProducts, loading } = this.state
    const shippingCode = parcel?.header.shippingCode
    const parcelCode = parcel?.header.parcelCode
    const destinationPlace = parcel?.header?.destinationPlace?.description || parcel?.header?.destinationPlace?.code
    const { detected, expected, unexpected } = ShipmentProvider.getCounters(parcel, checkListType)

    return (
      <Page
        title={this.operation?.description ?? 'Outbound'}
        onBackPress={this.navigateBack}
        loading={loading}
        header={{
          details: [
            { label: __(T.misc.shipment), value: shippingCode },
            { label: __(T.misc.parcel), value: parcelCode },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <TagCounter detected={detected} expected={expected} unexpected={unexpected} />
          <AntennaButton onClear={this.clear} hideClear={detected === 0} />

          <Box flex />

          <Button title={__(T.misc.confirm_parcel)} onClick={this.confirmOutbound} />
        </Page.Sidebar>

        <Page.Content>
          <ItemsStatesRow
            items={parcel?.detectedItems as TmrItem[]}
            onItemDeleteCallback={this.operation.removeMode !== 'none' ? this.removeItemFromReadings : undefined}
          />
          <GroupedProductList data={groupedProducts} noChecklist />
        </Page.Content>
      </Page>
    )
  }
}
