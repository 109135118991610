import api, { responseErrorCheck } from './api'
import { TmrWorkstation } from './types'

export default class Workstations {
  static endpoint = '/workstations'

  static searchByPlaceId(placeId) {
    return api
      .get<TmrWorkstation[]>(`${this.endpoint}`, {
        'place.id': placeId,
      })
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
