import { Grid, GridItem } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { CQLot } from 'api/types'
import { Modal, Input, Button, Box, KeyValueRow, EmptyList, Icons } from 'components'
import LotRow from 'components/LotRow'
import { Spacer } from 'components/Various'
import React, { Component } from 'react'
import { getField } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export interface SelectItemModalProps {
  onClose?: () => void
  visible?: boolean
  options: CQLot[]
  onSelect: (item: any[]) => void
  title?: string
  selected?: any[]
  lotCode: string
  date?: string
  multiple?: boolean
  searchable?: boolean
  forceSelect?: boolean
  serie: string
  modalId: string
}

interface State {
  filter: string
  selected: any[]
}

export default class SelectItemModal extends Component<SelectItemModalProps, State> {
  state: State = {
    filter: '',
    selected: this.props.selected ?? [],
  }

  onOptionSelect = (opt) => {
    const { multiple, lotCode, onSelect, onClose } = this.props
    const { selected } = this.state
    if (!multiple) {
      onSelect([opt])
      onClose?.()
      return
    }
    const itemAlreadySelected = selected.findIndex((s) => getField(s, lotCode) === getField(opt, lotCode))
    if (itemAlreadySelected >= 0) selected.splice(itemAlreadySelected, 1)
    else selected.push(opt)
    this.setState({ selected })
  }

  onSave = () => {
    const { onSelect, onClose } = this.props

    onSelect(this.state.selected)
    onClose?.()
  }

  onSearchEnter = () => {
    const { options, lotCode } = this.props
    const { filter } = this.state
    const filtered = options.filter((opt) => getField(opt, lotCode).toLowerCase().includes(filter.toLowerCase()))
    if (filtered.length === 1) {
      this.onOptionSelect(filtered[0])
      this.setState({ filter: '' })
    }
  }

  render() {
    const {
      onClose,
      visible,
      searchable,
      options,
      title = __(T.titles.select_item),
      lotCode,
      multiple,
      forceSelect,
      date,
      modalId,
      serie,
    } = this.props
    const { filter, selected } = this.state

    const filtered = options.filter((opt) => getField(opt, lotCode).toLowerCase().includes(filter.toLowerCase()))
    return (
      <Modal visible={visible} size={`3xl` as any} onClose={() => onClose?.()} fullContent>
        <Title>{title}</Title>
        {searchable && (
          <>
            <Input
              label={__(T.misc.filter)}
              onChange={(txt) => this.setState({ filter: txt })}
              onEnter={this.onSearchEnter}
            />
            <Spacer />
          </>
        )}
        <Box style={{ minHeight: '50vh', overflow: 'auto', margin: 0 }}>
          {filtered.length > 0 &&
            filtered.map((opt, index) => {
              const checkSelected = selected.find((s) => getField(s, lotCode) === getField(opt, lotCode))
              return (
                <LotRow
                  modalId={modalId}
                  lotCode={lotCode && getField(opt, lotCode)}
                  date={date && getField(opt, date)}
                  serie={serie}
                  lotData={opt}
                />
              )
            })}
          {filtered.length === 0 && <EmptyList />}
        </Box>
        {multiple && (
          <Box flex row mt={15}>
            {!forceSelect && (
              <>
                <Button variant="secondary" style={{ flex: 0 }} onClick={onClose}>
                  {__(T.misc.cancel)}
                </Button>
                <Spacer />
              </>
            )}
            <Button style={{ flex: 1 }} disabled={forceSelect && selected.length === 0} onClick={this.onSave}>
              {__(T.misc.save)}
            </Button>
          </Box>
        )}
      </Modal>
    )
  }
}

const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  padding: 10px;
  flex: 1;
  text-align: center;
`
