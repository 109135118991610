import { ShipmentParcel, ShipmentParcelPreview } from 'api/types'
import { OutboundConfig } from 'shared/RemoteConfig'

export async function outboundParcelBeforeConfirm(parcel: ShipmentParcel, operation: OutboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function outboundParcelAfterConfirm(parcel: ShipmentParcel, operation: OutboundConfig) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function outboundShipmentBeforeConfirm(
  shipment: ShipmentParcel,
  confirmPayload: any,
  operation: OutboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

export async function outboundShipmentAfterConfirm(
  parcel: ShipmentParcel,
  confirmResponse: ShipmentParcelPreview,
  operation: OutboundConfig
) {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}
