import React, { Component } from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { Box, Button, Icons } from 'components'
import { T, __ } from 'translations/i18n'

export interface Action {
  label: string
  callback: () => void
}

interface Props {
  actions: Action[]
}

export default class ActionsSelect extends Component<Props> {
  render() {
    const { actions } = this.props
    const rightButton: JSX.Element = <Icons.ArrowDown style={{ marginLeft: 15, width: 25 }} />
    return (
      <Box style={{ position: 'relative' }}>
        <Menu placement="bottom">
          <MenuButton as={Button}>
            <Box row>
              {__(T.misc.actions)} {rightButton}
            </Box>
          </MenuButton>
          <MenuList>
            {actions.map((action) => (
              <MenuItem style={{ fontSize: 25 }} onClick={action.callback}>
                {action.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    )
  }
}
