import React, { Component } from 'react'
import { Input, Page, Box, ParcelStatus, GroupedProductsTable, Icons, KeyValueRow } from 'components'
import { getMatchParams, navigate, getLocationState } from 'shared/router'
import { ShipmentParcel, GroupedShipmentProduct } from 'api/types'
import { getDatetime, showToast } from 'shared/utils'
import ShipmentProvider from 'ShipmentProvider'
import OutboundShipments from 'api/OutboundShipments'
import { T, __ } from 'translations/i18n'
import RemoteConfig, { OutboundConfig } from 'shared/RemoteConfig'

interface State {
  parcel?: ShipmentParcel
  filter: string
  loading: boolean
}

export default class extends Component {
  operation = RemoteConfig.getOperationConfig<OutboundConfig>(getMatchParams(this.props).configCode)

  state: State = {
    filter: '',
    loading: true,
  }

  async componentDidMount() {
    const { parcelCode } = getMatchParams(this.props)
    try {
      const detailResponse = await OutboundShipments.parcelDetail({ parcelCodes: [parcelCode] })
      this.setState({ loading: false, parcel: detailResponse ? detailResponse[0] : undefined })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.navigateBack()
    }
  }

  navigateBack = () => {
    const state = getLocationState(this.props)
    if (state.shippingCode)
      return navigate('/outbound/:configCode/:shippingCode/parcels', {
        configCode: getMatchParams(this.props).configCode,
        shippingCode: state.shippingCode,
      })

    return navigate('/outbound/:configCode', { configCode: getMatchParams(this.props).configCode })
  }

  setFilter = (filter: string) => this.setState({ filter })

  getFilteredProducts(products: GroupedShipmentProduct[], filter: string) {
    return products.filter(
      (product) =>
        product.product.code.toLowerCase().includes(filter) ||
        product.product.style?.value.toLowerCase().includes(filter) ||
        product.product.material?.value.toLowerCase().includes(filter) ||
        product.product.color?.value.toLowerCase().includes(filter) ||
        product.product.size?.value.toLowerCase().includes(filter)
    )
  }

  render() {
    const { parcel, filter, loading } = this.state
    const shippingCode = parcel?.header?.shippingCode
    const parcelCode = parcel?.header?.parcelCode
    const destinationPlace = parcel?.header?.destinationPlace?.description

    const groupedProducts = parcel ? ShipmentProvider.initializeGroupedProducts([parcel]) : []
    return (
      <Page
        loading={loading}
        title={`${this.operation?.description ?? 'Outbound'} / ${__(T.misc.detail)}`}
        onBackPress={this.navigateBack}
        header={{
          details: [
            { label: __(T.misc.shipment), value: shippingCode },
            { label: __(T.misc.parcel), value: parcelCode },
            { label: __(T.misc.destination), value: destinationPlace },
          ],
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              image={<Icons.Search />}
              onChange={this.setFilter}
              placeholder={__(T.misc.search)}
              style={{ width: 300 }}
            />
            <Box flex />
            <KeyValueRow
              variant="raw"
              style={{ padding: 0 }}
              loading={loading}
              label={__(T.misc.creation_date)}
              value={getDatetime(parcel?.header.parcelCreationDate)}
            />
            <KeyValueRow
              variant="raw"
              style={{ padding: 0, marginLeft: 40 }}
              loading={loading}
              label={__(T.misc.status)}
              value={<ParcelStatus status={parcel?.header?.parcelState ?? '---'} />}
            />
          </Page.TopBar>

          <GroupedProductsTable products={this.getFilteredProducts(groupedProducts, filter)} loading={loading} />
        </Page.Content>
      </Page>
    )
  }
}
