import React from 'react'
import { IdentifierType, TmrTag } from 'api/types'
import Lottie from 'react-lottie'
import styled from '@emotion/styled'
import { T, __ } from 'translations/i18n'
import AppStore from 'AppStore'
import { sleep } from 'shared/utils'
import * as Onde from 'assets/onde.json'
import { Box, Icons } from 'components'
import Button from './Button'
import EmulateButton from './EmulateButton'
import { Spacer } from './Various'

const BottomContainer = styled.div`
  // border-top: 1px solid #ccc;
`

const BottomInnerContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  height: 80px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.background1};
`

const ReadingAntenna = styled.b`
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
`

export default function AntennaBottomControl({
  onTagReadCallback,
  resetCallback,
  resetText,
  style,
}: {
  onTagReadCallback?: (tag: TmrTag) => void
  resetCallback?: () => void
  resetText?: string
  style?: React.CSSProperties
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (Onde as any).default,
    rendererSettings: {
      preserveAspectRatio: 'none',
    },
  }

  const onEmulate = async (tagType: IdentifierType) => {
    let codes: any = prompt('EPCs comma separeted', '')
    codes = codes?.replace(/ /g, '').split(',') ?? []
    for (const code of codes) {
      switch (tagType) {
        case 'NFCTag':
          onTagReadCallback && onTagReadCallback({ uid: code } as any)
          break
        default:
          onTagReadCallback && onTagReadCallback({ epc: code } as any)
          break
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(100)
    }
  }

  return (
    <BottomContainer>
      {onTagReadCallback && AppStore.emulation && (
        <Box row hcenter>
          <EmulateButton title="Emulate NFC" onPress={() => onEmulate('NFCTag')} />
          <Spacer />
          <EmulateButton title="Emulate UHF" onPress={() => onEmulate('UHFTag')} />
        </Box>
      )}
      <BottomInnerContainer style={style}>
        <Box>
          <Icons.AntennaOn style={{ height: 35 }} />
          <ReadingAntenna>{__(T.misc.reading)}</ReadingAntenna>
        </Box>
        <Spacer />

        <Lottie isClickToPauseDisabled style={{ width: '100%', height: '100%' }} options={defaultOptions} />
        {resetCallback && (
          <Button style={{ marginLeft: 20 }} onClick={resetCallback}>
            {resetText ?? __(T.misc.retry)}
          </Button>
        )}
      </BottomInnerContainer>
    </BottomContainer>
  )
}
