import React, { Component } from 'react'
import { List, Input, Page, Select, Spacer, Icons, Box, Button, ShipmentRow } from 'components'
import { InboundConfig } from 'shared/RemoteConfig'
import { navigate } from 'shared/router'
import { ShipmentPreview } from 'api/types'
import InboundShipments from 'api/InboundShipments'
import { showToast, uniqueBy } from 'shared/utils'
import { T, __ } from 'translations/i18n'
import AppStore from 'AppStore'

interface State {
  data: ShipmentPreview[]
  query: string
  originFilter?: ShipmentPreview
  origins?: ShipmentPreview[]
  loading?: boolean
}

interface Props {
  operation: InboundConfig
}

export default class InboundByShipment extends Component<Props, State> {
  operation = this.props.operation

  state: State = {
    data: [],
    query: '',
    loading: true,
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    try {
      const data = await InboundShipments.shipmentCounters({
        parcelStates: ['IN_TRANSIT', 'RECEIVE_AWAIT', 'IN_INBOUND', 'INBOUND_ERROR', 'READY_TO_RECEIVE'],
        destinationPlaceId: AppStore.loggedUser!.place!.id,
      })

      this.setState({
        loading: false,
        data: data ?? [],
        origins: uniqueBy(data ?? [], 'originPlaceCode'),
      })
    } catch (err) {
      this.setState({ loading: false, data: [] })
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onRowClick = (itm: ShipmentPreview) => {
    navigate('/inbound/:configCode/:shippingCode/parcels', {
      configCode: this.operation?.code,
      shippingCode: itm.shippingCode,
    })
  }

  onInputEnter = (input: string) => {
    const { data } = this.state
    this.setState({ query: input })
    const filtered = data.filter(this.filterResults)

    if (filtered.length === 1) {
      this.onRowClick(filtered[0])
    }
  }

  newParcel = () => {
    navigate('/inbound/:configCode/create', { configCode: this.operation?.code })
  }

  filterResults = (result: ShipmentPreview) =>
    result.shippingCode.toLowerCase().includes(this.state.query.toLowerCase()) &&
    ((this.state.originFilter && result.originPlace?.code === this.state.originFilter?.originPlace?.code) ||
      !this.state.originFilter)

  render() {
    const { data, origins, loading } = this.state
    const filtered = data.filter(this.filterResults)
    return (
      <Page title={`${this.operation?.description ?? 'Inbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Page.TopBar>
            <Input
              startFocus
              style={{ width: 350 }}
              image={<Icons.Barcode />}
              placeholder={__(T.placeholder.search_shipment_parcel)}
              onChange={(query) => this.setState({ query })}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Select
              transparent
              borderColor="transparent"
              containerStyle={{ width: 250 }}
              onSelect={(itm) => this.setState({ originFilter: itm })}
              options={origins ?? []}
              placeholder={__(T.misc.origin)}
              config={{
                value: 'header.originPlace.code',
                label: 'header.originPlace.description',
                secondaryLabel: 'header.originPlace.code',
              }}
            />
            <Box flex />
            {this.operation.hasChecklist === 'no' && (
              <Button title={__(T.misc.new_shipment)} onClick={this.newParcel} />
            )}
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.misc.parcels_to_receive)} (${filtered.length})`}
            renderItem={(itm) => <ShipmentRow operation={this.operation} shipment={itm} onRowClick={this.onRowClick} />}
            data={filtered}
          />
        </Page.Content>
      </Page>
    )
  }
}
